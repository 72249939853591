import { BrowserRouter } from "react-router-dom";
import Router from "./Routes";
import { AuthProvider } from "./contexts/auth.context";
import { QrCodeProvider } from "./contexts/scanner.context";
import { LoadScript } from "@react-google-maps/api";
const libraries = ["places"];

function App() {
    return (
        <LoadScript googleMapsApiKey="AIzaSyCS8bSpeUqqD4GX0VixlvaajNHRF0cFyE4" libraries={libraries}>
            <AuthProvider>
                <QrCodeProvider>
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </QrCodeProvider>
            </AuthProvider>
        </LoadScript>
    );
}

export default App;
