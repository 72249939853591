import React, { useEffect, useState } from 'react';
import './ListPage.scss';
import Spinner from '../spinner/Spinner';
import { checkUserPage, linkQrCodeToPage } from '../../firebase.service';

import eye from '../../assets/icons/eye.png';
import lock from '../../assets/icons/lock.png';
import hide from '../../assets/icons/hide.png';
import { useQrCode } from '../../contexts/scanner.context';
import { useNavigate } from 'react-router-dom';
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';

const translateStatus = (status, t) => {
    const icons = { public: eye, private: lock, hidden: hide };
    const text = { public: t('listPage.public'), private: t('listPage.private'), hidden: t('listPage.hidden'), default: t('listPage.unknown') };
    const icon = icons[status] || eye;
    const label = text[status] || text.default;
    return <p><img src={icon} alt={label} /> {label}</p>;
};

// const ListItem = ({ page, navigate }) => (
//     <div className='list-item' onClick={() => { qrCode ? navigate('/dashboard/edit/' + page.id) : linkQrCode() }}>
//         <img src={page.photoURL || 'https://flaviendev.b-cdn.net/default.jpg'} alt='item' />
//         <div className='list-item-content'>
//             <div className='list-item-name'>{page.name}</div>
//             <div className='list-item-desc'>
//                 {page.qrcodeID ? <span className='success'>Médaillon lié</span> : <span>Aucun médaillon</span>} &#8226; {translateStatus(page.status)}
//             </div>
//         </div>
//         <div className='right-arrow'></div>
//     </div>
// );

export default function ListPage({ refresh }) {
    const [isLoading, setIsLoading] = useState(false);
    const [pages, setPages] = useState([]);
    const { qrCode, resetQrCodeSession } = useQrCode();

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        setIsLoading(true);
        const response = await checkUserPage();
        setPages(response);
        setIsLoading(false);
    };

    const linkQrCode = async (pageId, qrCodeId) => {
        // console.log(pageId, qrCodeId)
        setIsLoading(true);
        await linkQrCodeToPage(pageId, qrCodeId);
        resetQrCodeSession();
        refresh();
        initialize();
        setIsLoading(false);
    }

    const renderPages = () => {
        // Filtrage initial des pages selon la présence ou non d'un QR Code
        const filteredPages = pages.filter(page => qrCode ? !page.qrcodeID : true);
    
        // Si aucune page ne correspond au critère, afficher un message
        if (filteredPages.length === 0) {
            return (
                <div>
                    <p className='mt-20 mb-20'>{qrCode ? t('listPage.noPage') : t('listPage.noPageFound')}</p>
                    {!qrCode && <Button text={t('listPage.createPage')} theme='primary' onClick={() => navigate('/dashboard/create')} icon='plus' />}
                </div>
            );
        }
    
        // Tri des pages par date de création, du plus récent au plus ancien
        const sortedPages = filteredPages.sort((a, b) => b.created.toDate() - a.created.toDate());
    
        return sortedPages.map((page, index) =>
            isLoading ? <Spinner /> :
            <div key={index} className='list-item' onClick={() => { !qrCode ? navigate('/dashboard/edit/' + page.id) : linkQrCode(page.id, qrCode) }}>
                <img src={page.photoURL || 'https://flaviendev.b-cdn.net/blank_profile.jpg'} alt='item' />
                <div className='list-item-content'>
                    <div className='list-item-name'>{page.name}</div>
                    <div className='list-item-desc'>
                        {page.qrcodeID ? <span className='success'>{t('listPage.medallionFound')}</span> : <span>{t('listPage.medallionNotFound')}</span>} &#8226; {translateStatus(page.status, t)}
                    </div>
                </div>
                <div className='right-arrow'></div>
            </div>
        );
    };    

    return (
        <div className='list-page-container'>
            {isLoading ? <Spinner /> : renderPages()}
        </div>
    );
}
