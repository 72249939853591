import { useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next'; // Assurez-vous d'avoir i18next configuré dans votre projet
import './AutoComplete.scss';

const AutoComplete = ({ location, handleChange }) => {
    const { t } = useTranslation();  // Utilisez i18next pour la traduction
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        fields: ["address_components", "geometry", "icon", "name"],
        // Suppression de componentRestrictions pour une couverture mondiale
        types: ["establishment"]  // Modifiez ou supprimez selon les besoins
    };
    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
            autoCompleteRef.current.addListener("place_changed", () => {
                const place = autoCompleteRef.current.getPlace();
                handleChange(place);
            });
        } else {
            console.error("Google Maps JavaScript API not loaded");
        }
    }, []);
    
    return <input className="location-input" ref={inputRef} placeholder={location ? location : t('Adresse de localisation')} />;
};

export default AutoComplete;
