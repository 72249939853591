import React, { useEffect, useState } from 'react';
import './Setting.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteLink, deletePage, getPageById, getQrCodeData, importSecondaryMedallion, removeSecondaryMedallion, resetQrCodePage, updatePageField } from '../../../firebase.service';
import Spinner from '../../../components/spinner/Spinner';
import Header from '../../../components/header/Header';
import Button from '../../../components/button/Button';
import ReactSwitch from 'react-switch';
import Saved from '../../../components/saved/Saved';
import Input from '../../../components/input/Input';
import { useTranslation } from 'react-i18next';
import Scanner from '../../../components/scanner/Scanner';

import medaillon from '../../../assets/icons/medaillon.png';
import { useQrCode } from '../../../contexts/scanner.context';

export default function Setting() {
    const [pageData, setPageData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const pageId = useParams().id;

    const [medallionData, setMedallionData] = useState({});

    const {t} = useTranslation();

    const [activeButton, setActiveButton] = useState(null);

    useEffect(() => {
        getPageData();
    }, []);

    const getPageData = async () => {
        const response = await getPageById(pageId);
        if(!response) { navigate('/dashboard/home'); return; }
        setPageData(response);
        if(response.qrcodeID)
            await qrCodeData(response.qrcodeID);
        setIsComment(response.isComment ? response.isComment : false);
        setIsModerate(response.isModerate ? response.isModerate : false);
        setIsShare(response.isShare ? response.isShare : false);
        setPagePassword(response.password ? response.password : '');
        setIsLoading(false);
    };

    const qrCodeData = async (qrcodeID) => {
        const response = await getQrCodeData(qrcodeID);
        if (response) {
            setMedallionData(response);
        }
        // console.log(response);
    }

    const toggleButton = (buttonName) => {
        setActiveButton(activeButton === buttonName ? null : buttonName);
    };

    const resetQrCode = async () => {
        if(pageData.secondary && pageData.secondary.length > 0) {
            alert(t('setting.alertMedallion3'));
            return;
        }
        const isConfirmed = window.confirm(t('setting.confirmDeleteMedallion'));
        if(isConfirmed) {
            setIsLoading(true);
            const pageID = pageData.id;
            const qrCode = pageData.qrcodeID;
            await resetQrCodePage(pageID, qrCode);
            await getPageData();
            setIsLoading(false);
        }
    }

    // activer les commentaires
    const [isComment, setIsComment] = useState(false);
    const handleComment = async nextComment => {
        setIsComment(nextComment);
        await updatePageField(pageData.id, 'isComment', nextComment);
        activeSaved();
    };

    // activer la modération des commentaires
    const [isModerate, setIsModerate] = useState(false);
    const handleModerate = async nextModerate => {
        setIsModerate(nextModerate);
        await updatePageField(pageData.id, 'isModerate', nextModerate);
        activeSaved();
    };

    // activer le bouton partager
    const [isShare, setIsShare] = useState(false);
    const handleShare = async nextShare => {
        setIsShare(nextShare);
        await updatePageField(pageData.id, 'isShare', nextShare);
        activeSaved();
    };

    const deletePageAndImage = async () => {
        const isConfirmed = window.confirm(t('setting.confirmDeletePage'));

        if (isConfirmed) {
            setIsLoading(true);
            await deletePage(pageData.id);
            navigate('/dashboard/home');
            setIsLoading(false);
        }
    }

    // gestion du status
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const changeStatus = async (status) => {
        setIsUpdatingStatus(true);
        await updatePageField(pageData.id, 'status', status);
        await getPageData();
        activeSaved();
        setIsUpdatingStatus(false);
    }
    // mot de passe de la page
    const [pagePassword, setPagePassword] = useState(0);
    const handlePasswordChange = (e) => {
        const newValue = e.target.value;
        const regex = /^[0-9]{0,20}$/;

        if (regex.test(newValue)) {
            setPagePassword(newValue);
        }
    };
    const changePassword = async () => {
        setIsUpdatingStatus(true);
        await updatePageField(pageData.id, 'password', pagePassword);
        await getPageData();
        activeSaved();
        setIsUpdatingStatus(false);
    }

    // alert saved
    const [isSaved, setIsSaved] = useState(false);
    const activeSaved = () => {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 2000);
    };

    // whitelist
    const [whitelist, setWhitelist] = useState('');
    const [isWhitelistLoading, setIsWhitelistLoading] = useState(false);
    const handleWhitelist = async (event) => {
        event.preventDefault();
        setIsWhitelistLoading(true);
        const currentWhitelist = pageData.whitelist && pageData.whitelist.length > 0 ? pageData.whitelist : [];
        const newWhitelist = [...currentWhitelist, whitelist];
        const updateResult = await updatePageField(pageData.id, 'whitelist', newWhitelist);
        if (updateResult.success) {
            setWhitelist('');
            setIsWhitelistLoading(false);
            getPageData();
            activeSaved();
        } else {
            console.log('Erreur');
            setIsWhitelistLoading(false);
        }
    };
    const deleteWhitelist = async (whitelist) => {
        setIsWhitelistLoading(true);
        await deleteLink(pageData.id, 'whitelist', whitelist);
        getPageData();
        setIsWhitelistLoading(false);
    }

    const { resetQrCodeSession } = useQrCode();
    // Secondary medallion
    const importSecondMedaillon = async (obj) => {
        setIsLoading(true);
        await importSecondaryMedallion(pageData.id, obj.code);
        await getPageData();
        resetQrCodeSession();
        setIsLoading(false);
    }
    const removeSecondMedaillon = async (qrcodeID) => {
        const isConfirmed = window.confirm(t('setting.confirmDeleteMedallion'));

        if (isConfirmed) {
            setIsLoading(true);
            await removeSecondaryMedallion(pageData.id, qrcodeID);
            await getPageData();
            setIsLoading(false);
        }
    }

    return (
        isLoading ? <Spinner /> : (
            <div className='container'>
                <Saved value={isSaved} />
                <Header back={() => navigate(`/dashboard/edit/${pageData.id}`)} title={pageData.name} subtitle={t('setting.params')} />
                <div className='content'>
                    {pageData.qrcodeID &&
                        <div className='setting-button mb-20' onClick={() => navigate(`/memory/${pageData.qrcodeID}`)}>
                            <div className='setting-button-icon view'></div>
                            <div className='setting-button-text'>
                                {t('setting.previewPage')}
                                {/* <small>Modifier le médaillon</small> */}
                            </div>
                            <div className='setting-button-arrow'></div>
                        </div>
                    }
                    <div className={`setting-button ${activeButton === 'medaillon' ? 'active' : ''}`} onClick={() => toggleButton('medaillon')}>
                        <div className='setting-button-icon'></div>
                        <div className='setting-button-text'>
                            {t('setting.changeMedallion')}
                            <small>{t('setting.updateMedallion')}</small>
                        </div>
                        <div className='setting-button-arrow'></div>
                    </div>
                    <div className={`setting-collapse ${activeButton === 'medaillon' ? 'active' : ''}`}>
                        {pageData && !pageData.qrcodeID ? (
                            <>
                                <p className='medium mb-20'>{t('setting.noMedallionLinked')}</p>
                                <Button text='Associer un médaillon' theme='primary' onClick={() => navigate(`/dashboard/home`)} />
                            </>
                        ) : (
                            <>
                                <h3 className='mb-10'>{t('setting.principalMedallion')}</h3>
                                <p><b>{t('setting.alertMedallionBold')}</b>, {t('setting.alertMedallion')}</p>
                                <p className='medium mb-20'>{t('setting.alertMedallion2')}</p>
                                <Button text={t('setting.desyncMedallion')} theme='danger' onClick={resetQrCode} />

                                {/* // MEDAILLON SECONDAIRE ############################### */}
                                <div className='setting-secondary-medallion'>
                                    <h3 className='mt-20 mb-10'>{t('setting.secondaryMedallion')}</h3>
                                    <p>{t('setting.secondaryDescription')}</p>
                                    <div className='setting-secondary-medallion-container mt-20'>
                                        <Scanner response={importSecondMedaillon} />

                                        {pageData.secondary && pageData.secondary.map((medallion, index) => (
                                            <div key={index} className='half-button'>
                                                <img src={medaillon} alt='medaillon' />
                                                {medallion.slice(-12)}
                                                <button onClick={() => removeSecondMedaillon(medallion)}>x</button>
                                            </div>
                                        ))}
                                    </div>

                                    {/* <button onClick={() => importSecondaryMedallion(pageData.id, '7613b8a4-ccf4-4fe2-bc8a-e66e5d4086ee')}>test button</button> */}
                                </div>
                            </>
                        )}
                    </div>                   

                    <div className={`setting-button mt-20 ${activeButton === 'access' ? 'active' : ''}`} onClick={() => toggleButton('access')}>
                        <div className='setting-button-icon lock'></div>
                        <div className='setting-button-text'>
                            {t('setting.confidentiality')}
                            <small>{t('setting.pageStatus')}</small>
                        </div>
                        <div className='setting-button-arrow'></div>
                    </div>
                    <div className={`setting-collapse ${activeButton === 'access' ? 'active' : ''}`}>
                        <p>{t('setting.statusVisibility')}</p>
                        <p><b>{t('setting.public')} :</b> {t('setting.publicDesc')}</p>
                        <p><b>{t('setting.hidden')} :</b> {t('setting.hiddenDesc')}</p>
                        <p><b>{t('setting.private')} :</b> {t('setting.privateDesc')}</p>

                        {isUpdatingStatus ? <Spinner type='button' /> :
                            <div className='radio-toolbar mt-20 mb-20'>
                                <input type='radio' id='public' name='status' checked={pageData.status === 'public' ? true : false} value='public' required onChange={(e) => changeStatus(e.target.value)} />
                                <label htmlFor='public'>{t('setting.public')}</label>

                                <input type='radio' id='hidden' name='status' checked={pageData.status === 'hidden' ? true : false} value='hidden' required onChange={(e) => changeStatus(e.target.value)} />
                                <label htmlFor='hidden'>{t('setting.hidden')}</label>

                                <input type='radio' id='private' name='status' checked={pageData.status === 'private' ? true : false} value='private' required onChange={(e) => changeStatus(e.target.value)} />
                                <label htmlFor='private'>{t('setting.private')}</label>
                            </div>
                        }

                        {pageData.status === 'private' && (isUpdatingStatus ? <Spinner type='button' /> : (
                            <>
                                <form className='setting-collapse-private' onSubmit={changePassword}>
                                    <Input type="number" placeholder="Code PIN pour débloquer la page" value={pagePassword} onBlur={changePassword} onChange={handlePasswordChange} label="Code PIN" />
                                </form>

                                <label className='label-global'>Whitelist</label>
                                <div className='input-plus'>
                                    <form className='input-plus-container' onSubmit={handleWhitelist}>
                                        <input
                                            type='email'
                                            placeholder='Email'
                                            value={whitelist}
                                            onChange={(e) => setWhitelist(e.target.value)}
                                            required
                                        />
                                        {isWhitelistLoading ? <div className='spinner-absolute'><Spinner type="button" /></div> : <button type='submit'></button>}
                                    </form>
                                    {pageData.whitelist && pageData.whitelist.map((whitelist, index) => (
                                        <div key={index} className='link-item'>
                                            {whitelist}
                                            {isWhitelistLoading ? <div style={{ transform: 'scale(0.5)', position: 'absolute', right: '0' }}><Spinner type='button' /></div> :
                                                <button onClick={() => deleteWhitelist(whitelist)}></button>}
                                        </div>
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>

                    <div className='setting-toggle-container'>
                        {medallionData && medallionData.animal ? null : (
                            <>
                                <div className='setting-toggle'>
                                    <p>{t('setting.activeComment')}</p>
                                    <ReactSwitch
                                        onChange={handleComment}
                                        checked={isComment}
                                        className="react-switch"
                                        onColor='#012E40'
                                    />
                                </div>
                                <div className='setting-toggle'>
                                    <p>{t('setting.moderateComment')}</p>
                                    <ReactSwitch
                                        onChange={handleModerate}
                                        checked={isModerate}
                                        className="react-switch"
                                        onColor='#012E40'
                                    />
                                </div>
                            </>
                        )}
                        <div className='setting-toggle'>
                            <p>{t('setting.shareButton')}</p>
                            <ReactSwitch
                                onChange={handleShare}
                                checked={isShare}
                                className="react-switch"
                                onColor='#012E40'
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '200px' }}>
                        {pageData.qrcodeID ? (
                            <div style={{ textAlign: 'center' }}>
                                <Button text={t('setting.deletePage')} theme='light' />
                                <small>{t('setting.deletePageAlert')}</small>
                            </div>
                        ) :
                            <Button text={t('setting.deletePage')} theme='danger' onClick={deletePageAndImage} />
                        }
                    </div>
                </div>
            </div>
        )
    );
}
