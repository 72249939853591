import React, { useCallback, useEffect, useState } from 'react';
import './EditInfo.scss';
import Input from '../../input/Input';
import { countEmptyOrMissingImagePages, deleteLink, updatePageField } from '../../../firebase.service';
import Saved from '../../saved/Saved';
import AutoComplete from '../../autoComplete/AutoComplete';

import { GoogleMap, Marker } from '@react-google-maps/api';
import Button from '../../button/Button';
import Spinner from '../../spinner/Spinner';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import CreateBiography from '../../create-biography-ia/CreateBiography';

export default function EditInfo({ data, refresh, qrCodeData }) {

    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // alert saved
    const [isSaved, setIsSaved] = useState(false);
    const activeSaved = () => {
        setIsSaved(true);
        refresh();
        setTimeout(() => setIsSaved(false), 2000);
    };

    // name
    const [name, setName] = useState(data.name);
    const updateName = async (e) => {
        e.preventDefault();
        if (e.target.value === data.name) return;
        if (e.target.value === '') {
            setName(data.name);
            return;
        }
        await updatePageField(data.id, 'name', name);
        activeSaved();
    }

    // birth date
    const [birth, setBirth] = useState(data.birth ? data.birth : '');
    const updateBirth = async (e) => {
        e.preventDefault();
        if (e.target.value === data.birth) return;
        // if (e.target.value === '') {
        //     setBirth(data.birth);
        //     return;
        // }
        await updatePageField(data.id, 'birth', birth);
        activeSaved();
    }

    // death date
    const [death, setDeath] = useState(data.death ? data.death : '');
    const updateDeath = async (e) => {
        e.preventDefault();
        if (e.target.value === data.death) return;
        // if (e.target.value === '') {
        //     setDeath(data.death);
        //     return;
        // }
        await updatePageField(data.id, 'death', death);
        activeSaved();
    }

    // location
    const [location, setLocation] = useState(data.location ? data.location.adresse.name : '');
    const [isLocationModified, setIsLocationModified] = useState(false);
    const [locationCoord, setLocationCoord] = useState(data.location ? data.location.coord : { lat: 48.8566, lng: 2.3522 });
    const [mapCenter, setMapCenter] = useState({ lat: 48.8566, lng: 2.3522 });
    const [markerPosition, setMarkerPosition] = useState(null);

    useEffect(() => {
        // console.log('locationCoord', locationCoord)
        setMapCenter(locationCoord);
        setMarkerPosition(locationCoord);
    }, [])

    const onMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarkerPosition({ lat, lng });
        setLocationCoord({ lat, lng });
        setIsLocationModified(true);
    }, []);

    const handleInputChangeInfoMaps = (place) => {
        // const address = place.name + ', ' + place.address_components.map(ac => ac.long_name).join(', ');
        // console.log(place)
        const locationCoord = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        };
        // set location + coord
        setLocation(place);
        setLocationCoord(locationCoord);
        // set marker position
        setMapCenter(locationCoord);
        setMarkerPosition(locationCoord);
        setIsLocationModified(true);
    };

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const isGooglePlacesObject = (location) => {
        return Array.isArray(location.address_components);
    };

    const displayLocation = async () => {
        setIsLoading(true);
        let locationFormatted = {
            ville: '',
            pays: '',
            rue: '',
            numero: '',
            name: location.name || ''
        };
        if (isGooglePlacesObject(location)) {
            location.address_components.forEach(component => {
                if (component.types.includes('locality')) {
                    locationFormatted.ville = component.long_name;
                } else if (component.types.includes('country')) {
                    locationFormatted.pays = component.long_name;
                } else if (component.types.includes('route')) {
                    locationFormatted.rue = component.long_name;
                } else if (component.types.includes('street_number')) {
                    locationFormatted.numero = component.long_name;
                }
            });
        } else {
            locationFormatted = data.location.adresse;
        }
        const locationData = {
            adresse: locationFormatted,
            coord: locationCoord
        }
        await updatePageField(data.id, 'location', locationData);
        setIsLocationModified(false);
        setIsLoading(false);
        activeSaved();
    }

    // react quill / description
    const [biographie, setBiographie] = useState(data.biographie ? data.biographie : '');
    const [updatedBio, setUpdatedBio] = useState(data.biographie ? data.biographie : '');

    const handleImport = (importedBio) => {
        setBiographie(importedBio);
        closeModal();
    };

    const updateBiographie = async () => {
        setIsLoading(true);
        await updatePageField(data.id, 'biographie', biographie);
        setUpdatedBio(biographie);
        activeSaved();
        setIsLoading(false);
    }

    // links
    const [link, setLink] = useState('');
    const [isLinkLoading, setIsLinkLoading] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLinkLoading(true);
        const currentLinks = data.links && data.links.length > 0 ? data.links : [];
        const newLinks = [...currentLinks, link];
        const updateResult = await updatePageField(data.id, 'links', newLinks);
        if (updateResult.success) {
            setLink('');
            setIsLinkLoading(false);
            activeSaved();
        } else {
            console.log('Erreur');
            setIsLinkLoading(false);
        }
    };

    // reset all info data
    const resetInfoData = async () => {
        const isConfirmed = window.confirm(t('editInfo.confirmRemoveData'));
        if (!isConfirmed) {
            return;
        }
        const list = [
            { name: 'birth', value: data.birth, resetState: () => setBirth('') },
            { name: 'death', value: data.death, resetState: () => setDeath('') },
            { name: 'biographie', value: data.biographie, resetState: () => setBiographie('') },
            {
                name: 'location',
                value: data.location?.adresse?.name,
                resetState: () => {
                    setLocation('');
                    setMapCenter({ lat: 48.8566, lng: 2.3522 });
                    setMarkerPosition(null);
                }
            },
            { name: 'links', value: data.links, resetState: () => setLink('') },
        ];

        for (const item of list) {
            if (item.value) { // Vérifie si la valeur existe avant de réinitialiser
                await updatePageField(data.id, item.name, null);
                // console.log('reset', item.name);
                item.resetState(); // Appelle la fonction pour réinitialiser l'état
            }
        }
        activeSaved();
    };

    const deleteLinkIntern = async (id, link) => {
        await deleteLink(id, 'links', link);
        activeSaved();
    }

    return (
        <div className='edit-info'>

            {/* alert saved */}
            <Saved value={isSaved} />

            {/* name */}
            <form onSubmit={updateName}>
                <Input type="text" onBlur={updateName} value={name} placeholder={t('editInfo.namePerson')} onChange={(e) => setName(e.target.value)} label={t('editInfo.namePerson')} />
            </form>

            {/* birth / death */}
            <label className='edit-info-label'>{t('editInfo.dateOfBirthAndDeath')}</label>
            <div className='edit-info-dateOfBirth'>
                <Input type='date' onBlur={updateBirth} value={birth} onChange={(e) => setBirth(e.target.value)} placeholder={t('editInfo.dateOfBirth')} />
                <Input type='date' onBlur={updateDeath} value={death} onChange={(e) => setDeath(e.target.value)} placeholder={t('editInfo.dateOfDeath')} />
            </div>

            {/* location */}
            <label className='edit-info-label'>{t('editInfo.location')}</label>
            <div className='edit-info-location'>
                {location !== '' && (
                    <>
                        <div className='edit-info-location-ajust mt-10'>
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                mapTypeId='satellite'
                                center={mapCenter}
                                zoom={15}
                                onClick={onMapClick}
                            >
                                {markerPosition && (
                                    <Marker position={markerPosition} />
                                )}
                            </GoogleMap>
                        </div>
                        {isLocationModified && <div className='edit-info-location-alert'>{t('editInfo.movePin')}</div>}
                    </>
                )}
                <AutoComplete name="location" location={location} handleChange={handleInputChangeInfoMaps} />
                {isLocationModified && (
                    isLoading ? <Spinner type="button" /> :
                        <Button onClick={displayLocation} theme='success' text={t('editInfo.confirmLocation')} />
                )}
            </div>
            
            {(!qrCodeData || !qrCodeData.animal) && (
                <div style={{ margin: '10px 0 20px' }}>
                    <div style={{ margin: '0 auto', textAlign: 'center'}}><small>Vous avez besoin d’inspiration ? <br />Laissez nous vous guider !</small></div>
                    <Button text='Générer une biographie' theme='robot' onClick={openModal} icon='robot' />
                </div>
            )}

            {/* description */}
            <label className='edit-info-label'>{t('editInfo.bio')}</label>
            <ReactQuill value={biographie} onChange={setBiographie} />
            {biographie !== updatedBio && (
                isLoading ? <Spinner type="button" /> :
                    <Button text={t('editInfo.save')} theme="success" onClick={updateBiographie} />
            )}

            {/* links */}
            <label className='edit-info-label mt-20'>{t('editInfo.link')}</label>
            <div className='input-plus'>
                <form className='input-plus-container' onSubmit={handleSubmit}>
                    <input
                        type='text'
                        placeholder={t('editInfo.addLink')}
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        required
                    />
                    {isLinkLoading ? <div className='spinner-absolute'><Spinner type="button" /></div> : <button type='submit'></button>}
                </form>
                {data.links && data.links.map((link, index) => (
                    <div key={index} className='link-item'>
                        <a href={link} target='_blank' rel='noreferrer'>{link}</a>
                        <button onClick={() => deleteLinkIntern(data.id, link)}></button>
                    </div>
                ))}
            </div>

            <div style={{ marginTop: '100px' }}><Button text={t('editInfo.resetData')} theme="danger" icon='close' onClick={resetInfoData} /></div>
            <CreateBiography isOpen={isModalOpen} onClose={closeModal} onImport={handleImport} />
        </div>
    )
}
