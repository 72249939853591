import React from 'react';
import './EditComment.scss';
import Comment from '../../comment/Comment';
import { useTranslation } from 'react-i18next';

export default function EditComment({ data, refresh }) {
    const comments = data?.comment || [];
    const {t} = useTranslation();
    return (
        <div className='edit-comment'>
            <div className='edit-comment-content'>
                {comments.length === 0 && t('editComment.noComment')} 
                {comments
                    .sort((a, b) => b.createAt.toDate() - a.createAt.toDate())
                    .map((comment, index) => {
                        return <Comment key={index} post={data} data={comment} refresh={refresh} />;
                    })}
            </div>
        </div>
    );
}

