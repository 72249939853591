import React, { useEffect, useState } from 'react';

import './MemoryTabs.scss';
import MemoryComment from '../memory-comment/MemoryComment';
import MemoryMedia from '../memory-media/MemoryMedia';
import { useTranslation } from 'react-i18next';

const MemoryTabs = ({ data, medallionData }) => {
    const [activeTab, setActiveTab] = useState(0);
    const { t } = useTranslation();

    // useEffect(() => {
    //     console.log('data tabs', data, medallionData);
    // }, [data, medallionData]);

    const containerStyle = {
        width: '300%',
        display: 'flex',
        transform: `translateX(${activeTab * -33.333}%)`,
        transition: 'transform 0.5s ease-in-out',
    };

    useEffect(() => {
        if (medallionData.animal) {
            setActiveTab(1);
        }
    }, [medallionData]);

    return (
        <div className='memory-tabs-container'>
            <div className='memory-tabs-buttons'>
                <div className='memory-tabs-buttons-dots-container'>

                    {!medallionData.animal ? (
                        <>
                            <button className={`${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>{t('memoryTabs.about')}</button>
                            <button className={`${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>{t('memoryTabs.photoVideo')}</button>
                            {data && data.isComment && !medallionData.animal && <button className={`${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>{t('memoryTabs.memory')}</button>}
                            <div className={`dots _${activeTab} ${data && !data.isComment ? 'cut' : ''}`}></div>
                        </>
                    ) : 
                    (
                        <>
                            <button className={`${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>{t('memoryTabs.about')}</button>
                            <button className={`${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>{t('memoryTabs.photoVideo')}</button>
                            <div className={`dots _${activeTab} cut`}></div>
                        </>
                    )}
                </div>
            </div>

            <div style={containerStyle}>
                <div className='memory-tab-content'>
                    <div className='memory-bio'>
                        {data && data.biographie ? <div className='ql-adapt' dangerouslySetInnerHTML={{ __html: data.biographie }}></div> : <div className='ql-adapt'>Aucune biographie</div>}
                        <div className='memory-links'>
                            {data && data.links && data.links.map((link, index) => (
                                <a key={index} href={link} target='_blank' rel='noreferrer'>{link}</a>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='memory-tab-content'>
                    {activeTab === 1 && <MemoryMedia data={data} />}
                </div>
                {data && data.isComment && activeTab === 2 && <div className='memory-tab-content'><MemoryComment uid={data.id} /></div>}
            </div>
        </div>
    );
};

export default MemoryTabs;
