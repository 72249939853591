import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { entrepriseCheck, logOutUser, qrCodeCheck } from '../../firebase.service';
import Spinner from '../../components/spinner/Spinner';
import { useAuth } from '../../contexts/auth.context';
import { useQrCode } from '../../contexts/scanner.context';
import MemoryLanding from '../../components/memory/memory-landing/MemoryLanding';

import './Memory.scss';
import logo from '../../assets/images/logo_dark.png';
import Button from '../../components/button/Button';
import { useTranslation } from 'react-i18next';

function maskEmail(email) {
    const [localPart, domain] = email.split('@');
    const start = localPart.substring(0, 2);
    const end = localPart.substring(localPart.length - 1, localPart.length);
    const maskedPart = '*'.repeat(localPart.length - 3);
    return `${start}${maskedPart}${end}@${domain}`;
}

export default function Memory() {
    const { code } = useParams();
    const { userData } = useAuth();
    const { checkAndSetQrCode } = useQrCode();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [qrCodeData, setQrCodeData] = useState({});
    const [jsonData, setJsonData] = useState(null);

    const [ownerEmail, setOwnerEmail] = useState('');
    const [entrepriseInfo, setEntrepriseInfo] = useState({});

    const [isOldCode, setIsOldCode] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        initialize();
        document.body.style.backgroundColor = '#EFEEFC';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    useEffect(() => {
        findEmailByCode(code)
    }, [jsonData]);

    const loadJsonData = async () => {
        try {
            const response = await fetch('/export.json');
            const data = await response.json();
            setJsonData(data);
        } catch (error) {
            console.error("Erreur lors du chargement des données JSON:", error);
        }
    };

    const findEmailByCode = (code) => {
        if (!jsonData) {
            // console.log("Les données ne sont pas encore chargées.");
            return;
        }
        for (let email in jsonData) {
            if (jsonData[email].includes(code)) {
                setIsOldCode(true);
                setOwnerEmail(email)
            }
        }
        return null;
    };

    // const verifyOwnerEmail = (e) => {
    //     e.preventDefault();
    //     console.log(confirmEmail);
    //     console.log(ownerEmail);
    //     if (confirmEmail === ownerEmail) {
    //         setIsConfirmEmail(true);
    //     }
    // }

    // const convertMedaillon = async () => {
    //     console.log('conversion');
    //     await createQRCodeList(code);
    //     initialize();
    // }

    const redirectionAfterLogOut = async () => {
        logOutUser();
        setTimeout(() => navigate('/login'), 1000)
    }

    const initialize = async () => {
        setIsLoading(true);
        const response = await qrCodeCheck(code);
        if (response) {
            setQrCodeData(response);
            checkAndSetQrCode(code);
            if (response.entreprise) {
                const infoEntreprise = await entrepriseCheck(response.entreprise);
                setEntrepriseInfo(infoEntreprise);
            }
        } else {
            await loadJsonData();
        }
        setIsLoading(false);
    };

    const isObjEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    if (isLoading) {
        return <Spinner />;
    }

    if (isObjEmpty(qrCodeData)) {
        return (
            isOldCode ?
                <div className='memory-land'>
                    <div className='memory-logo noMargin'>
                        <img src={logo} alt='logo' />
                    </div>
                    <div className='memory-card'>
                        <div className='memory-card-title red'>
                            Médaillon d'ancienne génération
                        </div>
                        <div className='memory-card-subtitle'>
                            Ce médaillon necessite une mise à jour et requiert une action du propriétaire.
                        </div>
                    </div>

                    {userData ? (
                        <div className='memory-card mt-20 mb-20'>
                            <div className='memory-card-subtitle'>
                                Vous êtes connecté avec le compte <span onClick={() => navigate('/dashboard')}>{userData.email}</span>
                            </div>

                            <Button text='Acceder à mon compte' theme='primary' icon='check' onClick={() => navigate('/dashboard')} />

                            <Button text='Se connecter avec un autre compte' theme='transparent' onClick={redirectionAfterLogOut} />
                        </div>
                    ) : (
                        <div className='memory-card mt-20 mb-20'>
                            <div className='memory-card-subtitle'>
                                Connectez vous avec l'adresse propriétaire du médaillon <b>"{maskEmail(ownerEmail)}"</b> pour le mettre à jour
                            </div>

                            <Button text='Je me connecte' theme='primary' icon='check' onClick={() => navigate('/login')} />
                        </div>
                    )}

                    <div className='memory-card'>
                        <Button text='Revenir à la boutique' theme='light' icon='back' onClick={() => window.location.href = 'https://dernierinstant.com/'} />
                    </div>
                </div>
                :
                <div className='memory-land'>
                    <div className='memory-logo'>
                        <img src={logo} alt='logo' />
                    </div>
                    <div className='memory-card'>
                        <div className='memory-card-title red'>
                            Ce médaillon n'est pas reconnu !
                        </div>
                        <div className='memory-card-subtitle'>
                            Si vous pensez que c'est une erreur, <span onClick={() => window.location.href = 'https://=dernierinstant.com/pages/contact'}>contactez nous</span>.
                        </div>

                        <Button text='Revenir à la boutique' theme='primary' icon='back' onClick={() => window.location.href = 'https://dernierinstant.com/'} />
                    </div>
                </div>
        );
    }

    if (!qrCodeData.available) {
        return <MemoryLanding uid={qrCodeData.page} partner={entrepriseInfo} />;
    }

    return (
        <div className='memory-land'>
            <div className='memory-logo'>
                <img src={logo} alt='logo' />
            </div>
            {entrepriseInfo && entrepriseInfo.length > 0 && (
                <div className='memory-card-partner' style={{backgroundColor: entrepriseInfo[0].color}}>
                    {t('memoryLanding.thankPartner')} <b>{entrepriseInfo[0].name}</b>
                    <img src={entrepriseInfo[0].logo} alt={entrepriseInfo[0].name} />
                    <small>{t('memoryLanding.partnerAgree')}</small>
                </div>
            )}
            {userData ? (
                <div className='memory-card'>
                    <div className='memory-card-title green'>
                        {t('memory.availableMedallion')}
                    </div>
                    <div className='memory-card-subtitle'>
                        {t('memory.youAreLogWith')} <span onClick={() => navigate('/dashboard')}>{userData.email}</span>
                    </div>

                    <Button text={t('memory.useThisAccount')} theme='primary' icon='check' onClick={() => navigate('/dashboard')} />

                    <Button text={t('memory.useAnotherAccount')} theme='transparent' onClick={redirectionAfterLogOut} />
                </div>
            ) : (
                <div className='memory-card'>
                    <div className='memory-card-title green'>
                        {t('memory.availableMedallion')}
                    </div>
                    <div className='memory-card-subtitle'>
                        {t('memory.connectToLink')}
                    </div>

                    <Button text={t('memory.login')} theme='primary' icon='check' onClick={() => navigate('/login')} />
                </div>
            )}
        </div>
    );
}
