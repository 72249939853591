import React, { useEffect, useState } from 'react';
import './EditVisit.scss';
// import { useTranslation } from 'react-i18next';
import VisitGraph from './graph';

export default function EditVisit({ data, refresh }) {
    // const { t } = useTranslation();
    const [visit, setVisit] = useState([]);

    useEffect(() => {
        // console.log('EditVisit data', data);
        if (data && data.visits) {
            const visitsArray = Object.keys(data.visits).map(key => ({
                ...data.visits[key],
                id: key
            }));
            setVisit(visitsArray);
            // console.log(visitsArray);
        }
        // console.log(t)
    }, [data]);

    return (
        <div>
            {/* <Alert text={t('editVisit.underConstruction')} theme='info' button={false} /> */}
            {visit.length > 0 ? <VisitGraph visits={visit} /> : 'No visits yet.'}
        </div>
    );
}