import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { deleteAlbum, deleteCloudinaryImage, removeImageFromAlbum, updateAlbumName, updateImageOrder } from '../../firebase.service';
import { thumbnail } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
import { format } from "@cloudinary/url-gen/actions/delivery";
import { auto } from "@cloudinary/url-gen/qualifiers/format";
import { Effect } from '@cloudinary/url-gen/actions/effect';
import Button from '../button/Button';
import { createAlbum, addImageToAlbum } from '../../firebase.service';
import { v4 as uuidv4 } from 'uuid';

import './Album.scss';

import trash from '../../assets/icons/trash.png';
import plus from '../../assets/icons/plus.png';
import back from '../../assets/icons/back.png';
import albumPic from '../../assets/icons/album.png';
import { useTranslation } from 'react-i18next';
import Spinner from '../spinner/Spinner';

const Album = ({ pageData, refresh, pictureMaxNumber }) => {
    const [albums, setAlbums] = useState([]);
    const [activeAlbumId, setActiveAlbumId] = useState(null);
    const [newAlbumName, setNewAlbumName] = useState('');
    // Ajouter un état pour le spinner
    const [isDeletingAlbum, setIsDeletingAlbum] = useState(false);
    const [isDeletingImage, setIsDeletingImage] = useState(false);
    const [isAddingImage, setIsAddingImage] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (pageData.albums) {
            const sortedAlbums = [...pageData.albums].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setAlbums(sortedAlbums);
        }
        // console.log(pageData);
    }, [pageData]);

    const cld = new Cloudinary({ cloud: { cloudName: 'dn6yhfcdn' } });

    const countTotalImages = (albums) => {
        return albums.reduce((total, album) => total + album.publicIds.length, 0);
    };

    // Fonction pour supprimer un album
    const handleDeleteAlbum = async (albumId) => {
        const isConfirmed = window.confirm(t('editMedia.confirmDelete'));
        if (!isConfirmed) {
            return;
        }
        const album = albums.find(album => album.id === albumId);
        if (album) {
            setIsDeletingAlbum(true);
            const response = await deleteAlbum(pageData.id, albumId, album.publicIds);
            setIsDeletingAlbum(false);
            if (response.success) {
                setAlbums(albums.filter(album => album.id !== albumId));
                if (activeAlbumId === albumId) {
                    setActiveAlbumId(null); // Revenir à la vue de tous les albums si l'album actif est supprim
                }
            } else {
                console.error('Error deleting album:', response.error);
            }
        }
    };

    const handleAlbumNameChange = async (albumId, newName) => {
        const response = await updateAlbumName(pageData.id, albumId, newName);
        if (response.success) {
            setAlbums(albums.map(album =>
                album.id === albumId ? { ...album, name: newName } : album
            ));
        } else {
            console.error('Error updating album name:', response.error);
        }
    };

    const handleDeleteImage = async (albumId, publicId) => {
        const isConfirmed = window.confirm(t('editMedia.confirmDelete'));
        if (!isConfirmed) {
            return;
        }
        setIsDeletingImage(true);
        
        await deleteCloudinaryImage(publicId);
        
        const response = await removeImageFromAlbum(pageData.id, albumId, publicId);
        if (response.success) {
            setAlbums(albums.map(album =>
                album.id === albumId ? { ...album, publicIds: album.publicIds.filter(id => id !== publicId) } : album
            ));
        } else {
            console.error('Error removing image from album:', response.error);
        }
        
        setIsDeletingImage(false);
    };
    

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        const sourceAlbumId = result.source.droppableId;
        const destinationAlbumId = result.destination.droppableId;

        if (sourceAlbumId !== destinationAlbumId) return;

        const album = albums.find(album => album.id === sourceAlbumId);
        const items = Array.from(album.publicIds);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);

        const updatedAlbums = albums.map(album =>
            album.id === sourceAlbumId ? { ...album, publicIds: items } : album
        );

        setAlbums(updatedAlbums);

        // Update Firestore with new image order
        await updateImageOrder(pageData.id, sourceAlbumId, items);
    };

    const handleAddAlbum = async () => {
        if (newAlbumName.trim() === '') return;
        const newAlbumId = uuidv4();  // Génère un identifiant unique
        const newAlbum = { id: newAlbumId, name: newAlbumName, publicIds: [], createdAt: new Date().toISOString() };
        setAlbums([newAlbum, ...albums]);
        setNewAlbumName('');

        // Create album in Firestore
        await createAlbum(pageData.id, newAlbumName, newAlbum.id, new Date().toISOString());
    };

    const handleEnterAlbum = (albumId) => {
        setActiveAlbumId(albumId);
    };

    const handleBackToAlbums = () => {
        setActiveAlbumId(null);
    };

    const showUploadWidget = () => {
        setIsAddingImage(true);
        const totalImages = countTotalImages(albums);
        const remainingImages = pictureMaxNumber - totalImages;
        console.log('Total Images:', totalImages);

        if (remainingImages <= 0) {
            alert("Vous avez atteint le nombre maximum d'images autorisées.");
            return;
        }

        window.cloudinary.openUploadWidget({
            cloudName: 'dn6yhfcdn',
            uploadPreset: 'preset_image',
            multiple: true,
            language: "fr",
            maxFileSize: 10485760,
            text: {
                "fr": {
                    "or": "Ou",
                    "back": "Retour",
                    "advanced": "Avancé",
                    "close": "Fermer",
                    "no_results": "Aucun résultat",
                    "search_placeholder": "Rechercher des fichiers",
                    "about_uw": "À propos du widget de téléversement",
                    "search": {
                        "placeholder": "Rechercher...",
                        "reset": "Réinitialiser la recherche"
                    },
                    "selection_counter": {
                        "selected": "Sélectionné"
                    },
                    "actions": {
                        "upload": "Téléverser",
                        "next": "Suivant",
                        "clear_all": "Tout effacer",
                        "log_out": "Déconnexion"
                    },
                    "local": {
                        "browse": "Parcourir",
                        "dd_title_single": "Glissez et déposez un fichier ici",
                        "dd_title_multi": "Glissez et déposez des fichiers ici",
                        "drop_title_single": "Déposez un fichier pour téléverser",
                        "drop_title_multiple": "Déposez des fichiers pour téléverser"
                    },
                    "notifications": {
                        "general_error": "Une erreur est survenue.",
                        "general_prompt": "Êtes-vous sûr ?",
                        "limit_reached": "Vous avez atteint la limite.",
                        "invalid_add_url": "L'URL doit être valide.",
                        "invalid_public_id": "L'ID public ne peut pas contenir \\,,&,#,%,<,>.",
                        "no_new_files": "Les fichiers ont déjà été téléversés.",
                        "image_purchased": "Image achetée",
                        "video_purchased": "Vidéo achetée",
                        "purchase_failed": "Échec de l'achat. Veuillez réessayer.",
                        "service_logged_out": "Service déconnecté en raison d'une erreur",
                        "great": "Super",
                        "image_acquired": "Image acquise",
                        "video_acquired": "Vidéo acquise",
                        "acquisition_failed": "Échec de l'acquisition. Veuillez réessayer."
                    },
                    "aria_label": {
                        "close": "Fermer",
                        "back": "Retour"
                    },
                    "queue": {
                        "title": "File d'attente de téléversement",
                        "title_uploading_with_counter": "Téléversement de {{num}} éléments",
                        "title_processing_with_counter": "Traitement de {{num}} éléments",
                        "title_uploading_processing_with_counters": "Téléversement de {{uploading}} éléments, traitement de {{processing}} éléments",
                        "title_uploading": "Téléversement d'éléments",
                        "mini_title": "Téléversé",
                        "mini_title_uploading": "Téléversement",
                        "mini_title_processing": "Traitement",
                        "show_completed": "Afficher les terminés",
                        "retry_failed": "Réessayer échoué",
                        "abort_all": "Tout annuler",
                        "upload_more": "Téléverser plus",
                        "done": "Terminé",
                        "mini_upload_count": "{{num}} téléversé",
                        "mini_failed": "{{num}} échoué",
                        "statuses": {
                            "uploading": "Téléversement...",
                            "processing": "Traitement...",
                            "timeout": "Un fichier volumineux est en cours de téléversement. Cela peut prendre un certain temps pour apparaître dans votre environnement de produit.",
                            "error": "Erreur",
                            "uploaded": "Terminé",
                            "aborted": "Annulé"
                        }
                    }
                }
            },
            resourceType: 'image',
            maxFiles: remainingImages,
            sources: [ 'local' ]
        }, async (error, result) => {
            if (error) {
                console.error('Upload Widget Error:', error);
                return;
            }

            console.log('Upload Widget Result:', result);

            if (result && result.event) {
                switch (result.event) {
                    case 'queues-end':
                        if (result.info && result.info.files) {
                            try {
                                const uploadedImages = result.info.files.map(file => file.uploadInfo.public_id);
                                console.log('Uploaded Images:', uploadedImages);

                                const response = await addImageToAlbum(pageData.id, activeAlbumId, uploadedImages);
                                console.log('Add Images to Album Response:', response);
                                setIsAddingImage(false);
                                if (response.success) {
                                    setAlbums(albums.map(album =>
                                        album.id === activeAlbumId ? { ...album, publicIds: [...album.publicIds, ...uploadedImages] } : album
                                    ));
                                } else {
                                    console.error('Error adding images to album:', response.error);
                                    setIsAddingImage(false);
                                }
                            } catch (err) {
                                console.error('Error processing uploaded images:', err);
                                setIsAddingImage(false);
                            }
                        }
                        break;
                    default:
                        console.log(`Event: ${result.event}`, result.info);
                        setIsAddingImage(false);
                        break;
                }
            } else {
                console.error('Unexpected result from Upload Widget:', result);
                setIsAddingImage(false);
            }
        });
    };

    return (
        isDeletingAlbum ? <Spinner /> :
            <div className='album-container'>
                {!activeAlbumId ? (
                    <>
                        <h3 className='mb-10' style={{ display: 'flex', justifyContent: 'space-between' }}>{t('editMedia.addPictures')} <small>{countTotalImages(albums)}/{pictureMaxNumber}</small></h3>
                        <div className='album-container-form'>
                            <input type="text" placeholder="Nom du nouvel album" maxLength='20' value={newAlbumName} onChange={(e) => setNewAlbumName(e.target.value)} />
                            <button type="button" onClick={handleAddAlbum}><img src={plus} /></button>
                        </div>
                        <div className='album-container-item'>
                            {albums.map(album => (
                                <button
                                    key={album.id}
                                    onClick={() => handleEnterAlbum(album.id)}
                                    style={album.publicIds[0] ? { backgroundImage: `url('https://res.cloudinary.com/dn6yhfcdn/image/upload/${album.publicIds[0]}')` } : {}}
                                >
                                    <img src={albumPic} />
                                    <h1>{album.name}</h1>
                                </button>
                            ))}
                            {albums.length === 0 && <p className='mt-20'><b>Aucun album</b><br /><br />Créer un album pour ajouter des photos</p>}
                        </div>
                    </>
                ) : (
                    <>
                        <div className='album-navigation-container'>
                            <button onClick={handleBackToAlbums}><img src={back} /></button>
                            <input
                                type='text'
                                value={albums.find(album => album.id === activeAlbumId).name}
                                maxLength='20'
                                onChange={(e) => setAlbums(albums.map(album =>
                                    album.id === activeAlbumId ? { ...album, name: e.target.value } : album
                                ))}
                                onBlur={(e) => handleAlbumNameChange(activeAlbumId, e.target.value)}
                            />
                            <button onClick={() => handleDeleteAlbum(activeAlbumId)}><img src={trash} /></button>
                        </div>
                        {isDeletingImage ? <Spinner /> : (
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId={activeAlbumId}>
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className='album-droppable'
                                        >
                                            <div style={{padding: '20px 20px 0'}}>
                                                {isAddingImage ? <Spinner /> : <Button theme='primary' onClick={showUploadWidget} text='Ajouter des photos' icon='plus' />}
                                            </div>
                                            {albums.find(album => album.id === activeAlbumId).publicIds.map((publicId, index) => {
                                                const img = cld.image(publicId)
                                                    .delivery(format(auto()))

                                                return (
                                                    <Draggable key={publicId} draggableId={publicId} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{ ...provided.draggableProps.style }}
                                                                className='album-draggable'
                                                            >
                                                                <AdvancedImage cldImg={img} />
                                                                <button className='delete-absolute-button' onClick={() => handleDeleteImage(activeAlbumId, publicId)}><img src={trash} /></button>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {albums.find(album => album.id === activeAlbumId).publicIds.length === 0 && <p style={{ padding: '20px' }}><b>Aucune image</b><br /><br />Ajouter des images à l'album</p>}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    </>
                )}
            </div>
    );
};

export default Album;
