import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import i18n from '../../../i18n';
import { format } from 'date-fns';
import { fr, enUS, es } from 'date-fns/locale'; // Importer les locales nécessaires

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const locales = {
    en: enUS,
    fr: fr,
    es: es,
};

function formatTimestampToRelativeDate(timestamp, dateFormat = 'd MMMM yyyy') {
    let date;
    let lang = locales[i18n.language] || enUS;

    if (timestamp && typeof timestamp.toDate === 'function') {
        date = timestamp.toDate();
    } else if (timestamp) {
        date = new Date(timestamp);
    } else {
        return '';
    }
    return format(date, dateFormat, { locale: lang });
}

export default function VisitGraph({ visits }) {
    const [dailyData, setDailyData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);

    useEffect(() => {
        const now = new Date();
        
        // Préparer les données quotidiennes pour les 14 derniers jours
        const last14Days = Array.from({ length: 14 }, (_, i) => {
            const date = new Date(now);
            date.setDate(date.getDate() - (13 - i));
            const day = date.toISOString().split('T')[0];
            return {
                date: day,
                count: 0,
            };
        });

        // Préparer les données mensuelles pour les 12 derniers mois
        const last12Months = Array.from({ length: 12 }, (_, i) => {
            const date = new Date(now);
            date.setMonth(date.getMonth() - (11 - i));
            const month = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
            return {
                month,
                count: 0,
            };
        });

        // Compter les vues par jour et par mois
        visits.forEach(visit => {
            const visitDate = new Date(visit.lastVisit);
            const visitDay = visitDate.toISOString().split('T')[0];
            const visitMonth = `${visitDate.getFullYear()}-${(visitDate.getMonth() + 1).toString().padStart(2, '0')}`;

            const dailyIndex = last14Days.findIndex(day => day.date === visitDay);
            if (dailyIndex !== -1) {
                last14Days[dailyIndex].count += visit.count;
            }

            const monthlyIndex = last12Months.findIndex(month => month.month === visitMonth);
            if (monthlyIndex !== -1) {
                last12Months[monthlyIndex].count += visit.count;
            }
        });

        setDailyData(last14Days);
        setMonthlyData(last12Months);
    }, [visits]);

    const dailyChartData = {
        labels: dailyData.map(day => formatTimestampToRelativeDate(day.date, 'd MMMM')),
        datasets: [
            {
                label: 'Vues par jour',
                data: dailyData.map(day => day.count),
                fill: false,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                tension: 0.1,
            },
        ],
    };

    const monthlyChartData = {
        labels: monthlyData.map(month => formatTimestampToRelativeDate(`${month.month}-01`, 'MMMM')),
        datasets: [
            {
                label: 'Vues par mois',
                data: monthlyData.map(month => month.count),
                fill: false,
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
                borderColor: 'rgba(153, 102, 255, 1)',
                tension: 0.1,
            },
        ],
    };

    const dailyOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' },
            title: { display: true, text: '14 derniers jours' },
        },
        scales: {
            x: {
                type: 'category',
                title: {
                    display: false
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Nombre de vues',
                },
            },
        },
    };

    const monthlyOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' },
            title: { display: true, text: '12 derniers mois' },
        },
        scales: {
            x: {
                type: 'category',
                title: {
                    display: false
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Nombre de vues',
                },
            },
        },
    };

    return (
        <div>
            <div className="chart-container">
                <b>Vues quotidiennes</b>
                <Line data={dailyChartData} options={dailyOptions} />
            </div>
            <div className="chart-container">
                <b>Vues mensuelles</b>
                <Line data={monthlyChartData} options={monthlyOptions} />
            </div>
        </div>
    );
}
