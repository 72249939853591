import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importez vos fichiers de traduction
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationES from './locales/es/translation.json';

// Les ressources de traduction
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    es: {
        translation: translationES
    }
};

i18n
    // connecte i18next à react-i18next
    .use(initReactI18next)
    // détecte la langue de l'utilisateur
    .use(LanguageDetector)
    // initialise i18next
    .init({
        resources,
        fallbackLng: 'fr', // langue par défaut
        debug: false, // passez à false en production

        interpolation: {
            escapeValue: false, // réagit déjà à l'évasion
        }
    });

export default i18n;
