import { Navigate, useRoutes } from "react-router-dom";
import HomeDashboard from "./pages/Dashboard/Home/Home.dashboard";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import { useAuth } from "./contexts/auth.context";
import Memory from "./pages/Memory/Memory";
import Create from "./pages/Dashboard/Create/Create";
import Edit from "./pages/Dashboard/Edit/Edit";
import Account from "./pages/Dashboard/Account/Account";
import Setting from "./pages/Dashboard/Setting/Setting";
import Admin from "./pages/Dashboard/Admin/Admin";
import Cancel from "./components/stripe/Cancel";
import Success from "./components/stripe/Success";
// import AdminV2 from "./pages/Dashboard/AdminV2/AdminV2";

export default function Router() {
    const { userData } = useAuth();

    const routes = useRoutes([
        {
            path: '/dashboard',
            element: userData ? <Dashboard /> : <Navigate to="/login" replace />,
            children: [
                { element: <Navigate to="/dashboard/home" />, index: true },
                { path: 'home', element: <HomeDashboard /> },
                { path: 'create', element: <Create /> },
                { path: 'edit/:id', element: <Edit />},
                { path: 'setting/:id', element: <Setting />},
                { path: 'account', element: <Account />},
                { path: 'admin', element: <Admin />}
            ],
        },
        {
            path: '/login',
            element: userData ? <Navigate to="/dashboard" replace /> : <Login />,
        },
        {
            path: '/subscription/cancel',
            element: <Cancel />,
        },
        {
            path: '/subscription/success',
            element: <Success />,
        },
        {
            path: '/memory/:code',
            element: <Memory />,
        },
        {
            path: '*',
            element: <Navigate to="/login" replace />,
        },
    ]);

    return routes;
}