import React, { useState, useEffect } from 'react';
import './ListUser.scss';
import Input from '../../input/Input';
import { checkIfEmailExist, checkUserPageByUID, convertPage, createPageByModerator, createQRCodeList, deleteMedaillon, linkQrCodeToPage, linkQrCodeToPageByModerator, qrCodeCheck, resetPasswordModerator, resetQrCodePage } from '../../../firebase.service';
import { useQrCode } from '../../../contexts/scanner.context';

export default function ListUser({ jsonData }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEmail, setSelectedEmail] = useState('');
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [userRegisterData, setUserRegisterData] = useState(null);
    const [userPagesData, setUserPagesData] = useState([]);

    const [reloadUserData, setReloadUserData] = useState(false);

    const [qrCodesValidity, setQrCodesValidity] = useState({});

    const { checkAndSetQrCode } = useQrCode();

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredEmails([]);
        } else {
            const filtered = Object.keys(jsonData.data)
                .filter(email => email.toLowerCase().includes(searchTerm.toLowerCase()))
                .slice(0, 10);
            setFilteredEmails(filtered);
            console.log('Filtered emails :', filtered)
        }
    }, [searchTerm, jsonData]);

    useEffect(() => {
        if (selectedEmail) {
            fetchUserData();
            setQrCodesValidity({});
            const emailCodes = jsonData.data[selectedEmail] || [];
            emailCodes.forEach(async (code) => {
                const isValid = await qrCodeCheck(code);
                setQrCodesValidity(prevState => ({ ...prevState, [code]: isValid }));
            });
        }
    }, [selectedEmail, reloadUserData]);

    const refreshUserData = () => {
        setReloadUserData(prev => !prev);
    };

    const fetchUserData = async () => {
        try {
            const response = await checkIfEmailExist(selectedEmail);
            console.log('Account :', response);
            if (response.length !== 0) {
                const pages = await checkUserPageByUID(response[0].uid);
                console.log('Pages :', pages);
                setUserPagesData(pages || []);
            }
            setUserRegisterData(response);
        } catch (error) {
            console.error("Erreur lors de la vérification de l'email:", error);
            setUserRegisterData(null);
        }
    };

    const handleEmailSelect = (email) => {
        setSelectedEmail(email);
        setSearchTerm('');
        setFilteredEmails([]);
    };

    const initConvertMedaillon = async (id) => {
        await createQRCodeList(id);
        refreshUserData();
    }

    const initConvertPage = async (id, pageOnly = false) => {
        // setIsLoading(true);
        if (userRegisterData.length === 0) {
            console.log(`Le compte est indisponible, demander à l'utilisateur de se connecter pour avoir accès à son uid`);
            return;
        }
        try {
            if (!jsonData || !jsonData.pages) {
                throw new Error("Données JSON ou pages manquantes");
            }
            if (userRegisterData.length === 0) {
                throw new Error("Compte n'existe pas");
            }
            const foundItem = jsonData.pages.find(item => item.id === id);
            if (foundItem) {
                if (!pageOnly) await createQRCodeList(id);
                const data = {
                    biographie: foundItem.description ? foundItem.description + '<br /><br />' + (foundItem.bio || null) : null,
                    birth: foundItem.birthDate || null,
                    created: foundItem.created || null,
                    death: foundItem.deathDate || null,
                    images: foundItem.photos || [],
                    id: foundItem.id,
                    links: foundItem.links || [],
                    name: foundItem.fullName || 'Nouveau utilisateur',
                    photoURL: foundItem.photoURL || null,
                    owner: userRegisterData[0].uid
                };
                const response = await convertPage(data);
                if (!response || !response._key || !response._key.path || !response._key.path.segments[1]) {
                    throw new Error("La réponse de la conversion de la page est invalide.");
                }
                await linkQrCodeToPage(response._key.path.segments[1], id);
            } else {
                await createQRCodeList(id);
                await checkAndSetQrCode(id);
            }
        } catch (error) {
            console.error("Erreur lors de l'opération :", error);
        } finally {
            refreshUserData();
        }
    };

    const deleteMed = async (id) => {
        const confirm = window.confirm('Supprimer le médaillon ?');
        if (confirm) {
            console.log(id);
            const response = await deleteMedaillon(id);
            console.log(response);
            refreshUserData();
        }
    }

    const recoverPass = async (user) => {
        const mail = user.toLowerCase();
        console.log('Envoi de reset password par mail : ', mail);
        const response = await resetPasswordModerator(mail);
        console.log(response)
    }

    const createNewPage = async (uid) => {
        console.log(uid)
        const response = await createPageByModerator('Nouvelle page', 'hidden', uid);
        console.log(response);
        refreshUserData();
    }

    const linkMedaillonToPage = async (idPage, code, uid) => {
        const response = await linkQrCodeToPageByModerator(idPage, code, uid);
        console.log(response);
        refreshUserData();
    }

    const resetMedaillonFromPage = async (idPage, code) => {
        const response = await resetQrCodePage(idPage, code);
        console.log(response);
        refreshUserData();
    }

    // const deletePage = async (id) => {
    //     const response = await deletePage(id);
    //     console.log(response);
    // }

    return (
        <div className='mt-20'>
            <h1>Liste ancienne génération</h1>
            <Input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Rechercher par email..."
            />
            <ul className="autocomplete">
                {filteredEmails.map(email => (
                    <li key={email} onClick={() => handleEmailSelect(email)}>{email}</li>
                ))}
            </ul>

            {selectedEmail && (
                <div className="email-info">
                    <h2>{selectedEmail}</h2>
                    <button onClick={refreshUserData}>Rafrachir</button>
                    {userRegisterData !== null ? (userRegisterData.length !== 0 ? (
                        <div>
                            Utilisateur inscris : id : {userRegisterData[0].uid}
                        </div>
                    ) : (
                        <div>
                            Compte non existant <button onClick={() => recoverPass(selectedEmail)}>Reset password</button>
                        </div>
                    )) : 'Chargement...'}
                    <ul>
                        {jsonData.data[selectedEmail]?.map((code, index) => (
                            <li key={index}>
                                {qrCodesValidity[code] && !userPagesData.some(page => page.qrcodeID === code) ? (
                                    <>
                                        <div>QR Code {code} valide</div>
                                        <button onClick={() => deleteMed(code)}>Delete</button>
                                        <button onClick={() => initConvertPage(code, true)}>Convertir page</button>
                                        <button onClick={() => createNewPage(userRegisterData[0].uid)}>Créer nouvelle page</button>
                                    </>
                                ) : qrCodesValidity[code] ? (
                                    <>
                                        <div>Page {code} existante</div>
                                        <div>{JSON.stringify(userPagesData.find(page => page.qrcodeID === code))}</div>
                                    </>
                                ) : (
                                    <>
                                    <button onClick={() => initConvertPage(code)}>Convertir médaillon {code} et créer page si elle existe</button>
                                    <button onClick={() => initConvertMedaillon(code)}>Convertir uniquement médaillon</button>
                                    </>
                                )}

                                <div>
                                    Liste de page disponible
                                    {userPagesData.length !== 0 && userPagesData.map((item, indexPage) => {
                                        return (
                                            <div key={indexPage}>
                                                {item.name}
                                                <button onClick={() => linkMedaillonToPage(item.id, code, userRegisterData[0].uid)}>Lier médaillon</button>
                                                <button onClick={() => resetMedaillonFromPage(item.id, code)}>reset médaillon</button>
                                                {/* <button onClick={() => deletePage(item.id)}>Supprimer page</button> */}
                                            </div>
                                        )
                                    })}
                                </div>
                            </li>
                        )) || 'Aucun code disponible'}
                    </ul>
                </div>
            )}
        </div>
    );
}
