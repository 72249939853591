import React, { useState, useEffect } from 'react';
import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { format } from "@cloudinary/url-gen/actions/delivery";
import { auto } from "@cloudinary/url-gen/qualifiers/format";
import './VideoUploader.scss';
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';
import { deleteCloudinaryImage, deleteVideoCloudinary, updateVideo } from '../../firebase.service';

import trash from '../../assets/icons/trash.png';
import Spinner from '../spinner/Spinner';

const VideoUploader = ({ pageData, refresh, videoMaxNumber }) => {
    const [videos, setVideos] = useState([]);
    const [isAddingVideo, setIsAddingVideo] = useState(false);
    const [isDeletingVideo, setIsDeletingVideo] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (pageData.videos) setVideos(pageData.videos);
    }, [pageData]);

    const cld = new Cloudinary({ cloud: { cloudName: 'dn6yhfcdn' } });

    const showUploadWidget = () => {
        setIsAddingVideo(true);
        window.cloudinary.openUploadWidget({
            cloudName: 'dn6yhfcdn',
            uploadPreset: 'preset_video',
            multiple: false,
            resourceType: 'video',
            language: "fr",
            maxFileSize: 41943040,
            text: {
                "fr": {
                    "or": "Ou",
                    "back": "Retour",
                    "advanced": "Avancé",
                    "close": "Fermer",
                    "no_results": "Aucun résultat",
                    "search_placeholder": "Rechercher des fichiers",
                    "about_uw": "À propos du widget de téléversement",
                    "search": {
                        "placeholder": "Rechercher...",
                        "reset": "Réinitialiser la recherche"
                    },
                    "selection_counter": {
                        "selected": "Sélectionné"
                    },
                    "actions": {
                        "upload": "Téléverser",
                        "next": "Suivant",
                        "clear_all": "Tout effacer",
                        "log_out": "Déconnexion"
                    },
                    "local": {
                        "browse": "Parcourir",
                        "dd_title_single": "Glissez et déposez un fichier ici",
                        "dd_title_multi": "Glissez et déposez des fichiers ici",
                        "drop_title_single": "Déposez un fichier pour téléverser",
                        "drop_title_multiple": "Déposez des fichiers pour téléverser"
                    },
                    "notifications": {
                        "general_error": "Une erreur est survenue.",
                        "general_prompt": "Êtes-vous sûr ?",
                        "limit_reached": "Vous avez atteint la limite.",
                        "invalid_add_url": "L'URL doit être valide.",
                        "invalid_public_id": "L'ID public ne peut pas contenir \\,,&,#,%,<,>.",
                        "no_new_files": "Les fichiers ont déjà été téléversés.",
                        "image_purchased": "Image achetée",
                        "video_purchased": "Vidéo achetée",
                        "purchase_failed": "Échec de l'achat. Veuillez réessayer.",
                        "service_logged_out": "Service déconnecté en raison d'une erreur",
                        "great": "Super",
                        "image_acquired": "Image acquise",
                        "video_acquired": "Vidéo acquise",
                        "acquisition_failed": "Échec de l'acquisition. Veuillez réessayer."
                    },
                    "aria_label": {
                        "close": "Fermer",
                        "back": "Retour"
                    },
                    "queue": {
                        "title": "File d'attente de téléversement",
                        "title_uploading_with_counter": "Téléversement de {{num}} éléments",
                        "title_processing_with_counter": "Traitement de {{num}} éléments",
                        "title_uploading_processing_with_counters": "Téléversement de {{uploading}} éléments, traitement de {{processing}} éléments",
                        "title_uploading": "Téléversement d'éléments",
                        "mini_title": "Téléversé",
                        "mini_title_uploading": "Téléversement",
                        "mini_title_processing": "Traitement",
                        "show_completed": "Afficher les terminés",
                        "retry_failed": "Réessayer échoué",
                        "abort_all": "Tout annuler",
                        "upload_more": "Téléverser plus",
                        "done": "Terminé",
                        "mini_upload_count": "{{num}} téléversé",
                        "mini_failed": "{{num}} échoué",
                        "statuses": {
                            "uploading": "Téléversement...",
                            "processing": "Traitement...",
                            "timeout": "Un fichier volumineux est en cours de téléversement. Cela peut prendre un certain temps pour apparaître dans votre environnement de produit.",
                            "error": "Erreur",
                            "uploaded": "Terminé",
                            "aborted": "Annulé"
                        }
                    }
                }
            },
            sources: ['local'],
            maxVideoFileSize: 41943040,
            transformation: [{ quality: 'auto:low' }]
        }, async (error, result) => {
            if (error) {
                console.error('Upload Widget Error:', error);
                return;
            }

            console.log('Upload Widget Result:', result);

            if (result && result.event === 'queues-end' && result.info && result.info.files) {
                try {
                    const uploadedVideos = result.info.files.map(file => file.uploadInfo.public_id);
                    console.log('Uploaded Videos:', uploadedVideos[0]);

                    const reponse = await updateVideo(uploadedVideos[0], pageData.id);
                    console.log('Response:', reponse);
                    setIsAddingVideo(false);
                    refresh();
                } catch (err) {
                    console.error('Error processing uploaded videos:', err);
                }
            }
            setIsAddingVideo(false);
        });
    };

    const handleDeleteVideo = async (publicId) => {
        const isConfirmed = window.confirm(t('editMedia.confirmDelete'));
        if (!isConfirmed) {
            return;
        }
        setIsDeletingVideo(true);
        await deleteVideoCloudinary(publicId, pageData.id);
        refresh();
        setIsDeletingVideo(false);
    };

    return (
        <div className='video-uploader-container'>
            <h3 className='mb-10' style={{ display: 'flex', justifyContent: 'space-between' }}>{t('editMedia.addVideos')} <small>{videos ? videos.length : 0}/{videoMaxNumber}</small></h3>
            {isAddingVideo ? <Spinner /> : (
                videos && videos.length < videoMaxNumber && <Button theme='primary' onClick={showUploadWidget} text='Ajouter des vidéos' icon='plus' />
            )}
            {videos && videos.length >= videoMaxNumber && <Button text='Limite atteinte' theme='danger' />}
            {isDeletingVideo ? <Spinner /> : (
                <div className='video-list'>
                    {videos.map((publicId, index) => {
                        const video = cld.video(publicId).delivery(format(auto()));
                        return (
                            <div key={index} className='video-item'>
                                <AdvancedVideo cldVid={video} controls />
                                <button onClick={() => handleDeleteVideo(publicId)}><img src={trash} /></button>
                            </div>
                        );
                    })}
                    {/* {videos.length === 0 && <p className='mt-20'><b>Aucune vidéo</b></p>} */}
                </div>
            )}
        </div>
    );
};

export default VideoUploader;
