import React from 'react';
import './ProfileBanner.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ProfileBanner({ user }) {

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className='profile-banner-container'>
            <div className='profile-banner-name'>
                <small>{t('profileBanner.hello')}</small>
                {user && user.displayName}
            </div>
            <img src={user && user.photoURL ? user.photoURL : 'https://flaviendev.b-cdn.net/blank_profile.jpg'} onClick={() => navigate('/dashboard/account')} alt='profile banner' />
        </div>
    )
}
