import React from 'react';
import './Spinner.scss';

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import spinner from '../../assets/images/animation-spinner-6.json';

export default function Spinner({ type }) {
    return (
        <div className={`spinner-container ${type}`}>
            {/* <img src={spinner} alt='Chargement..' /> */}
            <Player
                autoplay
                loop
                src={spinner}
                className='spinner'
            >
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
        </div>
    )
}
