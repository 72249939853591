import React, { createContext, useState, useContext } from 'react';
import { qrCodeCheck } from '../firebase.service';

const QrCodeContext = createContext();

export const QrCodeProvider = ({ children }) => {
    const [qrCode, setQrCode] = useState(null);
    const [qrCodeStatus, setQrCodeStatus] = useState('');

    const checkAndSetQrCode = async (code) => {
        try {
            const isLinked = await qrCodeCheck(code);
            if (isLinked !== null) {
                if (isLinked.available === false) {
                    setQrCodeStatus('Code déjà lié');
                    setQrCode(null);
                    return { response: 'used', message: 'Médaillon déjà utilisé' };
                } else {
                    setQrCode(code);
                    setQrCodeStatus('Code disponible');
                    return { response: 'available', message: 'Médaillon disponible', code: isLinked.uid };
                }
            } else {
                setQrCodeStatus('Code invalide');
                setQrCode(null);
                return { response: 'invalid', message: 'Médaillon invalide' };
            }
        } catch (error) {
            console.error('Erreur lors de la vérification du code QR:', error);
            setQrCodeStatus('Erreur lors de la vérification');
            return { error: error };
        }
    };
    
    const resetQrCodeSession = () => {
        setQrCode(null);
    };

    return (
        <QrCodeContext.Provider value={{ qrCode, qrCodeStatus, checkAndSetQrCode, resetQrCodeSession }}>
            {children}
        </QrCodeContext.Provider>
    );
};

export const useQrCode = () => {
    const context = useContext(QrCodeContext);
    if (context === undefined) {
        throw new Error('useQrCode doit être utilisé à l\'intérieur d\'un QrCodeProvider');
    }
    return context;
};

export default QrCodeContext;
