import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import './UploadPicture.scss';
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage';
import { imageDb } from '../../firebase.config';
import { v4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';

const UploadPicture = forwardRef(({ onUploadStart, destination, onUploadSuccess }, parentRef) => {
    const fileInputRef = useRef();
    const { t } = useTranslation();

    useImperativeHandle(parentRef, () => ({
        triggerFileInput: () => {
            fileInputRef.current.click();
        }
    }));

    const handleFileChange = async (e) => {
        e.preventDefault();
        const files = Array.from(e.target.files);
        if (!files.length) return;

        // Définissez les formats acceptés et la taille maximale (ex: 5MB)
        const acceptedFormats = ['image/jpeg', 'image/png'];
        const maxSize = 10 * 1024 * 1024; // 10MB

        // Options de compression
        const options = {
            maxSizeMB: 1, // La taille maximale après compression (1MB dans cet exemple)
            maxWidthOrHeight: 1920, // La largeur ou la hauteur maximale de l'image après compression
            useWebWorker: true
        };

        for (const file of files) {
            // Vérifiez le format du fichier
            if (!acceptedFormats.includes(file.type)) {
                alert(t('uploadPicture.invalidFormat'));
                continue;
            }

            // Vérifiez la taille du fichier
            if (file.size > maxSize) {
                alert(t('uploadPicture.invalidSize'));
                continue;
            }

            try {
                if (onUploadStart) {
                    onUploadStart();
                }

                // Compression de l'image
                const compressedFile = await imageCompression(file, options);

                const itemId = v4();
                const path = `${destination}/${itemId}`;
                const imgRef = storageRef(imageDb, path);

                // Upload de l'image compressée
                await uploadBytes(imgRef, compressedFile);
                const url = await getDownloadURL(imgRef);

                if (onUploadSuccess) {
                    onUploadSuccess(url, destination);
                }

            } catch (e) {
                console.error(`Erreur lors du téléchargement : ${e.message}`);
            }
        }
    };

    return (
        <div className='upload'>
            <input
                type='file'
                accept="image/jpeg,image/png" // Assurez-vous de limiter les types de fichiers ici aussi
                ref={fileInputRef}
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    );
});

export default UploadPicture;
