// Params
// ########################################
// type : string (text, password, email)
// placeholder : string
// onChange : function
// label : string
// value : string (optional)
// icon : string (optional)
// onBlur : function (optional)
// ########################################
// Example
// <Input type="text" placeholder="Entrez votre nom" onChange={(e) => console.log(e.target.value)} label="Nom" />

import React from 'react';
import './Input.scss';

export default function Input({type, placeholder, onChange, label, required, value, icon, onBlur}) {
    return (
        <>
            <label className='label'> <span>{label}</span> 
                <input onBlur={onBlur} className={`input ${icon ? 'icon ' + icon : ''}`} type={type} placeholder={placeholder} value={value && value} onChange={onChange} required={required} />
            </label>
        </>
    )
}
