import React, { useEffect, useState } from 'react';

import { QrScanner } from '@yudiel/react-qr-scanner';
import scan from '../../assets/icons/scan.png';

import './Scanner.scss';
import { useQrCode } from '../../contexts/scanner.context';
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';

export default function Scanner({ response }) {

    const { checkAndSetQrCode } = useQrCode();
    const [showScanner, setShowScanner] = useState(false);

    useEffect(() => {
        if (showScanner) {
            document.body.classList.add('no-scroll');
            window.scrollTo(0, 0);
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [showScanner]);


    const { t } = useTranslation();

    const resultQrCodeScan = async (result) => {
        setShowScanner(false);
        console.log(result);
        const url = new URL(result);
        const pathSegments = url.pathname.split('/');
        const qrCode = pathSegments.pop() || pathSegments.pop();
        const responseCode = await checkAndSetQrCode(qrCode);
        console.log(responseCode);
        if (responseCode.response === 'available') {
            response(responseCode)
            // navigate('/dashboard/pages/create');
        } else {
            response(responseCode)
        }
    }

    return (
        <>
            {showScanner && (
                <>
                    <div className='overlay-scanner' onClick={() => setShowScanner(false)}></div>
                    <div className='scanner-modal'>
                        <QrScanner
                            onDecode={(result) => resultQrCodeScan(result)}
                            onError={(error) => console.log(error?.message)}
                            scanDelay={1000}
                        />
                        <div style={{ width: 'calc(100% - 30px)', margin: '0 auto' }}><Button text={t('scanner.back')} icon='back' theme='primary' onClick={() => setShowScanner(false)} /></div>
                    </div>
                </>
            )}
            <div className='half-button' onClick={() => setShowScanner(true)}>
                <img src={scan} alt='scanner' />
                <b>{t('scanner.scan')}</b>
                <small>{t('scanner.linkScan')}</small>
            </div>
        </>
    )
}
