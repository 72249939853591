import React, { useEffect, useState } from 'react';
import './MemoryComment.scss';
import { getPageById } from '../../../firebase.service';

import { formatDistanceToNow } from 'date-fns';
import { fr, enUS, es } from 'date-fns/locale';

import trash from '../../../assets/icons/trash.png';

import { getUserById, removeAnswerFromComment, removeComment, toggleLikeOnComment } from '../../../firebase.service';
import Spinner from '../../spinner/Spinner';
import { useAuth } from '../../../contexts/auth.context';
import NewComment from '../../comment/newComment/NewComment';
import NewAnswer from '../../comment/newAnswer/NewAnswer';
import { useNavigate } from 'react-router-dom';
import Button from '../../button/Button';

import { ref as storageRef, deleteObject } from "firebase/storage";
import { imageDb } from '../../../firebase.config';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export default function MemoryComment({ uid }) {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingAnswers, setLoadingAnswers] = useState({});
    const [loadingComments, setLoadingComments] = useState({});
    const { userData } = useAuth();

    const {t} = useTranslation();

    useEffect(() => {
        fetchData();
    }, [uid]);

    const fetchData = async () => {
        setIsLoading(true);
        const response = await getPageById(uid);

        const commentsWithUserAndAnswers = await Promise.all((response.comment || []).map(async (comment) => {
            // Assurez-vous que le commentaire existe
            if (!comment) return null;
        
            // Récupération des informations de l'utilisateur pour le commentaire
            const userInfo = await getUserById(comment.userID);
        
            // Récupération des informations de l'utilisateur pour chaque réponse du commentaire
            const answersWithUserInfo = await Promise.all((comment.answer || []).map(async (answer) => {
                // Assurez-vous que la réponse existe
                if (!answer) return null;
        
                const answerUserInfo = await getUserById(answer.userID);
                return { ...answer, userInfo: answerUserInfo }; // Ajoute les infos de l'utilisateur à la réponse
            }).filter(answer => answer !== null)); // Filtrez les réponses nulles
        
            return { ...comment, userInfo, answer: answersWithUserInfo }; // Combine les infos du commentaire, de l'utilisateur et des réponses
        }).filter(comment => comment !== null)); // Filtrez les commentaires nuls
        
        setData({ ...response, comment: commentsWithUserAndAnswers });
        
        setIsLoading(false);
    };


    const deleteComment = async (comment) => {
        const { userInfo, ...originalComment } = comment;
        const confirm = window.confirm(t('memoryComment.deleteComment'));
        if (confirm) {
            if(comment.image) {
                const imageRef = storageRef(imageDb, comment.image);
                await deleteObject(imageRef);
            }
            setLoadingComments(prevState => ({ ...prevState, [originalComment.id]: true })); // Démarre le chargement pour ce commentaire
            await removeComment(uid, originalComment);
            fetchData().then(() => {
                setLoadingComments(prevState => ({ ...prevState, [originalComment.id]: false })); // Arrête le chargement pour ce commentaire
            });
        }
    };

    const deleteAnswer = async (commentId, answerId) => {
        const confirm = window.confirm(t('memoryComment.deleteAnswer'));
        if (confirm) {
            setLoadingAnswers(prevState => ({ ...prevState, [answerId]: true })); // Démarre le chargement pour cette réponse
            await removeAnswerFromComment(uid, commentId, answerId);
            fetchData().then(() => {
                setLoadingAnswers(prevState => ({ ...prevState, [answerId]: false })); // Arrête le chargement pour cette réponse
            });
        }
    };

    const [isLikeLoading, setIsLikeLoading] = useState(false);
    const likeComment = async (commentId) => {
        if (userData === null) {
            return;
        }
        setIsLikeLoading(true);

        // const updatedComments = data.comment.map(c => {
        //     if (c.id === commentId) {
        //         const hasLiked = c.like.includes(userData.uid);
        //         const updatedLikes = hasLiked
        //             ? c.like.filter(uid => uid !== userData.uid)
        //             : [...c.like, userData.uid];
        //         return { ...c, like: updatedLikes };
        //     }
        //     return c;
        // });

        // setData({ ...data, comment: updatedComments });

        await toggleLikeOnComment(uid, commentId, userData.uid);
        await fetchData();
        setIsLikeLoading(false);
    };


    function formatTimestampToRelativeDate(timestamp) {
        let date;
        if (timestamp && typeof timestamp.toDate === 'function') {
            date = timestamp.toDate();
        } else if (timestamp) {
            date = new Date(timestamp);
        } else {
            return '';
        }
        const locales = { fr, en: enUS, es };
        const locale = locales[i18next.language.slice(0, 2)];
        return formatDistanceToNow(date, { addSuffix: true, locale });
    }

    const navigate = useNavigate();
    const connection = () => {
        console.log('Se connecter');
        console.log(data.qrcodeID);
        localStorage.setItem('redirect', data.qrcodeID);
        const savedData = localStorage.getItem('redirect');
        console.log(savedData);
        navigate('/login');
    }

    return (
        isLoading ? <Spinner type='page' /> :
            <>
                {userData ? <NewComment pageID={uid} onSendComplete={() => fetchData()} /> : (
                    <div className='mb-40'>
                        <Button type='button' text={t('memoryComment.loginForPost')} theme='primary' icon='check' onClick={connection} />
                    </div>
                )}

                {data && data.comment.length === 0 && t('memoryComment.noComment')}

                {data && data.comment && data.comment.sort((a, b) => b.createAt.toDate() - a.createAt.toDate()).map((comment, index) => {

                    const shouldDisplayComment = !data.isModerate || comment.moderated || (userData && userData.uid === comment.userID);

                    return (
                        shouldDisplayComment ? (
                            <div className='comment' key={index}>
                                {loadingComments[comment.id] ? <Spinner type='button' /> : (
                                    <>
                                        <div className='comment-header'>
                                            <img src={comment.userInfo.photoURL ? comment.userInfo.photoURL : 'https://flaviendev.b-cdn.net/blank_profile.jpg'} alt='avatar' />
                                            <div className='comment-header-title'>
                                                <div className='comment-header-title-name'>
                                                    <span>{comment.userInfo.displayName}</span> {userData && comment.userID === userData.uid && <button onClick={() => deleteComment(comment)}><img src={trash} alt='Delete' /></button>}
                                                </div>
                                                <div className='comment-header-title-date'>
                                                    <span>{formatTimestampToRelativeDate(comment.createAt)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {comment.image &&
                                            <div className='comment-image'>
                                                <img src={comment.image} alt='comment' />
                                            </div>
                                        }
                                        <div className='comment-text'>
                                            {data.isModerate && !comment.moderated && <small style={{ display: 'block', marginBottom: '5px' }}><i>{t('memoryComment.moderationInProgress')}</i></small>}
                                            {comment.message}
                                        </div>

                                        {(!data.isModerate || comment.moderated) && (
                                            <>
                                                <div className='comment-footer'>
                                                    {isLikeLoading ? <button className='like'>Loading</button> : <button className='like' onClick={() => likeComment(comment.id)}>{comment.like.length} like{comment.like.length > 1 && 's'}</button>} - <button>{comment.answer.length} {t('memoryComment.comment')}{comment.answer.length > 1 && 's'}</button>
                                                </div>

                                                {comment.answer && comment.answer.map((answer, indexAnswer) => {
                                                    return (
                                                        <div className='comment-answer' key={indexAnswer}>
                                                            {loadingAnswers[answer.id] ? <Spinner type='button' /> : (
                                                                <>
                                                                    <div className='comment-header'>
                                                                        <img src={answer.userInfo?.photoURL || 'https://flaviendev.b-cdn.net/blank_profile.jpg'} alt='avatar' />
                                                                        <div className='comment-header-title'>
                                                                            <div className='comment-header-title-name'>
                                                                                <span>{answer.userInfo?.displayName || 'Nom inconnu'}</span> {userData && (userData.uid === answer.userID || userData.uid === comment.userID) && <button onClick={() => deleteAnswer(comment.id, answer.id)}><img src={trash} alt='Delete' /></button>}
                                                                            </div>
                                                                            <div className='comment-header-title-date'>
                                                                                <span>{answer && formatTimestampToRelativeDate(answer.createAt)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='comment-text'>{answer.message}</div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                                {userData && <NewAnswer pageID={uid} commentID={comment.id} onSendComplete={() => fetchData()} />}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : null
                    )
                })}
            </>



    )
}
