import React, { useEffect, useState } from 'react';
import { displayProductName, getAllStripeSubscriptions } from '../../firebase.service';
import { useAuth } from '../../contexts/auth.context';
import { formatDistanceToNow } from 'date-fns';
import { fr, enUS, es } from 'date-fns/locale';
import i18next from 'i18next';

import './StripeActivity.scss';

export default function StripeActivity({ pageData, licences }) {
    const [subscriptions, setSubscriptions] = useState(licences);
    const { userData } = useAuth();

    const formatTimestampToRelativeDate = (timestamp) => {
        let date;
        if (timestamp && typeof timestamp.toDate === 'function') {
            date = timestamp.toDate();
        } else if (timestamp && typeof timestamp === 'number') {
            date = new Date(timestamp * 1000);
        } else {
            return '';
        }
        const locales = { fr, en: enUS, es };
        const locale = locales[i18next.language.slice(0, 2)] || enUS;
        return formatDistanceToNow(date, { addSuffix: true, locale });
    };

    const displayState = (state) => {
        switch (state) {
            case 'incomplete': return 'En cours..';
            case 'incomplete_expired': return 'Expiré';
            case 'trialing': return 'Essai';
            case 'past_due': return 'Retard';
            case 'canceled': return 'Annulé';
            case 'unpaid': return 'Impayé';
            case 'paid': return 'Payé';
            case 'active': return 'Actif';
            default: return '';
        }
    };

    const displaySubscriptionState = (state) => {
        switch (state) {
            case 'customer.subscription.updated': return 'Abonnement mis à jour';
            case 'customer.subscription.created': return 'Création abonnement';
            case 'customer.subscription.deleted': return 'Abonnement annulé';
            case 'invoice.payment_succeeded': return 'Paiement confirmé';
            default: return '';
        }
    };

    // Regrouper les événements par ID de session et page_id
    const groupedEvents = subscriptions.flatMap(subscription => subscription.events)
        .filter(event => event.page_id === pageData.id && event.data.status !== 'open')
        .reduce((acc, event) => {
            const subscriptionId = event.data.subscription_id;
            if (!acc[subscriptionId]) {
                acc[subscriptionId] = [];
            }
            acc[subscriptionId].push(event);
            return acc;
        }, {});


    // Trier les groupes par le plus récent événement dans chaque groupe
    const sortedSubscriptionIds = Object.keys(groupedEvents)
        .sort((a, b) => {
            const latestEventA = groupedEvents[a].sort((x, y) => y.event_created - x.event_created)[0];
            const latestEventB = groupedEvents[b].sort((x, y) => y.event_created - x.event_created)[0];
            return latestEventB.event_created - latestEventA.event_created;
        });


    return (
        <div>
            <div className='mb-10'>
                <b>Historique</b>
            </div>
            {subscriptions && subscriptions.length > 0 ? (
                <div className='history-container'>
                    {sortedSubscriptionIds.map((subscriptionId) => (
                        <div key={subscriptionId} className='subscription-group'>
                            {groupedEvents[subscriptionId]
                                .sort((a, b) => b.event_created - a.event_created)
                                .map((event, index) => (
                                    <div className='history-item' key={index}>
                                        <div className='history-item-header'>
                                            <div className='history-item-type'>
                                                {displaySubscriptionState(event.event_type)}
                                                <small>{event.event_type}</small>
                                            </div>
                                            {event.data.status && event.data.status !== '' &&
                                                <div className={`history-item-state ${event.data.status}`}>
                                                    {displayState(event.data.status)}
                                                </div>
                                            }
                                        </div>
                                        <div className='history-item-date'>
                                            {formatTimestampToRelativeDate(event.event_created)}
                                        </div>

                                        {event.data && event.data.plan && (
                                            <div className='history-item-price'>
                                                Produit :
                                                <span>
                                                    {event.data && event.data.plan && displayProductName(event.data.plan.product)}
                                                    <b>{event.data.plan.amount / 100}€</b>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}

                            <small>ID : {subscriptionId}</small>
                        </div>
                    ))}
                </div>
            ) : (
                <p>Aucun abonnement trouvé.</p>
            )}
        </div>
    );
}
