// Params
// ########################################
// text: string
// theme: string (success, danger, light, primary, secondary)
// onClick: function
// icon: string (optional)
// small: boolean (optional) default false
// type: string (optional) default button
// ########################################
// Example
// <Button text="Click me" theme="primary" onClick={() => console.log('clicked')} icon='google' />

import React from 'react';
import './Button.scss';

// icons
import google from '../../assets/icons/google.png';
import back from '../../assets/icons/back.png';
import lock from '../../assets/icons/lock.png';
import mail from '../../assets/icons/mail.png';
import eye from '../../assets/icons/eye.png';
import hide from '../../assets/icons/hide.png';
import setting from '../../assets/icons/setting.png';
import power from '../../assets/icons/power.png';
import plus from '../../assets/icons/plus.png';
import check from '../../assets/icons/checked.png';
import trash from '../../assets/icons/trash.png';
import close from '../../assets/icons/close.png';
import history from '../../assets/icons/history.png';
import warning from '../../assets/icons/warning.png';
import download from '../../assets/icons/downloading.png';
import robot from '../../assets/icons/robot.png';

// Mapping des icônes
const ICONS = {
    google: google,
    back: back,
    lock: lock,
    mail: mail,
    eye: eye,
    hide: hide,
    setting: setting,
    power: power,
    plus: plus,
    check: check,
    trash: trash,
    close: close,
    history: history,
    warning: warning,
    download: download,
    robot: robot
};

export default function Button({ text, theme, onClick, icon, small, type }) {
    const iconImg = ICONS[icon];
    return <button type={type ? type : 'button'} className={small ? `btn small ${theme}` : `btn ${theme}`} onClick={onClick}>{icon && <span className='icon'><img src={iconImg} alt={icon} /></span>} {text}</button>
}
