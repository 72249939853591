import React, { useState } from 'react';
import './NewAnswer.scss';
import Input from '../../input/Input';
import { useAuth } from '../../../contexts/auth.context';
import { addAnswerToComment } from '../../../firebase.service';
import Spinner from '../../spinner/Spinner';
import checked from '../../../assets/icons/checked.png';

import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

export default function NewAnswer({ pageID, commentID, onSendComplete }) {

    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    const { userData } = useAuth();
    // useEffect(() => {
    //     console.log(userData)
    // }, [userData])

    const [message, setMessage] = useState('');
    const addAnswer = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            id: uuidv4(),
            createAt: new Date(),
            userID: userData.uid,
            message: message
        }
        await addAnswerToComment(pageID, commentID, data);
        setMessage('');
        onSendComplete();
        setIsLoading(false);
    }

    return (
        <div>
            {isLoading ? <Spinner type='button' /> :
                <form className='new-answer-container' onSubmit={addAnswer}>
                    <Input type='text' value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t('newAnswer.yourAnswer')} required />
                    <button type='submit'><img src={checked} alt='checked' /> {t('newAnswer.postAnswer')}</button>
                </form>
            }
        </div>
    )
}
