import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Logo from '../../components/logo/Logo';

export default function Dashboard() {

    const navigate = useNavigate();

    useEffect(() => {

        const redirection = localStorage.getItem('redirect');
        if (redirection) {
            navigate('/memory/' + redirection);
        }

        document.body.style.backgroundColor = '#012E40';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);
    return (
        <>
            <Logo />
            <Outlet />
        </>
    )
}
