import React, { useState } from 'react';

// styles
import './Login.scss';
import logo from '../../assets/images/logo_dark.png';

// elements
import Button from '../../components/button/Button';
import Input from '../../components/input/Input';
import { resetPassword, userLoginByEmail, userLoginByGoogle } from '../../firebase.service';
import Alert from '../../components/alert/Alert';
import RegisterForm from '../../components/Register.form';
import Spinner from '../../components/spinner/Spinner';
import LanguageSelector from '../../components/languageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next';

// landing login
function Landing({ navigateBetweenTabs }) {
    const { t } = useTranslation();
    return (
        <div className='login-container'>
            <div className='login-logo'>
                <img src={logo} alt='logo' onClick={() => window.location.href = 'https://dernierinstant.com/'} />
            </div>
            <div className='login-card'>
                <div className='login-card-title'>
                    {t('loginPage.loginRegister')}
                </div>
                <div className='login-card-subtitle'>
                    {t('loginPage.description')}
                </div>
                <Button text={t('loginPage.login')} theme='primary' onClick={() => navigateBetweenTabs('login')} />
                <Button text={t('loginPage.createAccount')} theme='light' onClick={() => navigateBetweenTabs('register')} />

                <div className='mt-20'>
                    <LanguageSelector />
                </div>
            </div>
        </div>
    )
}

// login
function Connexion({ navigateBetweenTabs }) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [alertInfo, setAlertInfo] = useState({ visible: false, theme: '', message: '' });

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await userLoginByEmail(email, password);
        if (response.success) {
            setAlertInfo({ visible: true, theme: 'success', message: t('loginPage.loginProgress') });
        }
        if (response.error) {
            if (response.error.code === 'auth/invalid-credential') {
                setAlertInfo({ visible: true, theme: 'error', message: t('loginPage.invalidCredential') });
            } else {
                setAlertInfo({ visible: true, theme: 'error', message: t('loginPage.errorHappen') });
            }
        }
        setIsLoading(false);
    }

    const handleLoginWithGoogle = async () => {
        await userLoginByGoogle();
        setAlertInfo({ visible: true, theme: 'success', message: t('loginPage.loginProgress') });
    }

    const hideAlert = () => {
        setAlertInfo({ ...alertInfo, visible: false });
    };

    return (
        <>
            <div className='login-title-container'>
                <Button type='button' onClick={() => navigateBetweenTabs('landing')} icon='back' small />
                <span>Connexion</span>
            </div>
            <div className='login-content'>
                <Button type='button' text={t('loginPage.loginWithGoogle')} theme='light' onClick={() => handleLoginWithGoogle()} icon='google' />
                <div className='login-or'>
                    <span className='line'></span>
                    <span className='text'>{t('loginPage.or')}</span>
                    <span className='line'></span>
                </div>
                {alertInfo.visible && (
                    <Alert theme={alertInfo.theme} text={alertInfo.message} onClose={hideAlert} />
                )}
                <form onSubmit={handleSubmitForm}>
                    <Input type='email' icon='email' placeholder={t('loginPage.addressMail')} label={t('loginPage.addressMail')} onChange={(e) => setEmail(e.target.value)} required />
                    <Input type='password' icon='password' placeholder={t('loginPage.password')} label={t('loginPage.password')} onChange={(e) => setPassword(e.target.value)} required />
                    {isLoading ? <Spinner type='button' /> : <Button type='submit' text={t('loginPage.login')} theme='primary' />}

                    <div className='login-infos mt-20 mb-20'>
                        {t('loginPage.noAccount')} <b onClick={() => navigateBetweenTabs('register')} style={{ cursor: 'pointer' }}>{t('loginPage.register')}</b>
                    </div>

                    <Button type='button' text={t('loginPage.forgotPassword')} theme='transparent' onClick={() => navigateBetweenTabs('forget')} />
                </form>

                <div className='login-infos mt-40'>
                {t('loginPage.conditionDesc')} <b>{t('loginPage.conditionGeneral')}</b> & <b>{t('loginPage.conditionPrivacy')}</b>.
                </div>
            </div>
        </>
    )
}

// register
function Register({ navigateBetweenTabs }) {
    const [alertInfo, setAlertInfo] = useState({ visible: false, theme: '', message: '' });
    const {t} = useTranslation();
    const handleLoginWithGoogle = async () => {
        await userLoginByGoogle();
        setAlertInfo({ visible: true, theme: 'success', message: 'Connexion en cours..' });
    }
    const hideAlert = () => {
        setAlertInfo({ ...alertInfo, visible: false });
    };
    return (
        <>
            <div className='login-title-container'>
                <Button type='button' onClick={() => navigateBetweenTabs('landing')} icon='back' small />
                <span>Inscription</span>
            </div>
            {alertInfo.visible && (
                <Alert theme={alertInfo.theme} text={alertInfo.message} onClose={hideAlert} />
            )}
            <div className='login-content'>
                <Button type='button' text={t('loginPage.registerByMail')} theme='primary' onClick={() => navigateBetweenTabs('register-form')} icon='mail' />
                <Button type='button' text={t('loginPage.registerByGoogle')} theme='light' onClick={() => handleLoginWithGoogle()} icon='google' />

                <div className='login-infos mt-40'>
                    {t('loginPage.haveAccount')} <b onClick={() => navigateBetweenTabs('login')} style={{ cursor: 'pointer' }}>{t('loginPage.login')}</b>
                </div>

                <div className='login-infos mt-40'>
                    {t('loginPage.conditionDesc')} <b>{t('loginPage.conditionGeneral')}</b> & <b>{t('loginPage.conditionPrivacy')}</b>.
                </div>
            </div>
        </>
    )
}

// forget
function Forget({ navigateBetweenTabs }) {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [alertInfo, setAlertInfo] = useState({ visible: false, theme: '', message: '' });
    const hideAlert = () => {
        setAlertInfo({ ...alertInfo, visible: false });
    };
    const reset = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await resetPassword(email);
        console.log(response);
        if (response.success) {
            setAlertInfo({ visible: true, theme: 'success', message: t('loginPage.emailSendToYou') });
        } else {
            setAlertInfo({ visible: true, theme: 'error', message: t('loginPage.unknownEmail') });
        }
        setIsLoading(false);
    }
    return (
        <>
            <div className='login-title-container'>
                <Button type='button' onClick={() => navigateBetweenTabs('login')} icon='back' small />
                <span>{t('loginPage.forgotPassword')}</span>
            </div>
            <div className='login-content'>
                {alertInfo.visible ? (
                    <Alert theme={alertInfo.theme} text={alertInfo.message} onClose={hideAlert} />
                ) : (
                    <div className='login-infos mt-20 mb-40'>
                        {t('loginPage.forgotDesc')}
                    </div>
                )}
                <form onSubmit={(e) => reset(e)}>
                    <Input type='email' placeholder={t('loginPage.addressMail')} label={t('loginPage.addressMail')} onChange={(e) => setEmail(e.target.value)} icon='email' required />
                    {isLoading ? <Spinner type='button' /> : <Button type='submit' text={t('loginPage.send')} theme='primary' />}
                </form>
            </div>
        </>
    )
}

// render
export default function Login() {

    const [page, setPage] = useState('landing');
    const navigateBetweenTabs = (page) => {
        setPage(page);
    }

    switch (page) {
        case 'login':
            return <Connexion navigateBetweenTabs={navigateBetweenTabs} />
        case 'register':
            return <Register navigateBetweenTabs={navigateBetweenTabs} />
        case 'register-form':
            return <RegisterForm navigateBetweenTabs={navigateBetweenTabs} />
        case 'forget':
            return <Forget navigateBetweenTabs={navigateBetweenTabs} />
        default:
            return <Landing navigateBetweenTabs={navigateBetweenTabs} />
    }
}
