import React, { useEffect, useState } from 'react';
import './ProgressBar.scss';
import { useTranslation } from 'react-i18next';

export default function ProgressBar({ steps, current }) {
    const [calculation, setCalculation] = useState(0);
    const {t} = useTranslation();
    useEffect(() => {
        setCalculation(current / steps * 100);
    }, [current, steps])
    return (
        <>
            <div className='stepOnStep'>{current} {t('progressBar.on')} {steps}</div>
            <div className='progress-bar-container'>
                <div className='progress-bar' style={{width: calculation + '%'}}></div>
            </div>
        </>
    )
}
