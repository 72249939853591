import React, { useEffect, useState } from 'react';
import './Admin.scss';
import { useAuth } from '../../../contexts/auth.context';

import Qrcodes from '../../../components/admin/QrCodes';
import ListUser from '../../../components/admin/list-user/ListUser';

export default function Admin() {

    const { userData } = useAuth();

    const [nav, setNav] = useState('home');
    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        loadJsonData();
    }, [])

    const loadJsonData = async () => {
        try {
            const exportCode = await fetch('/export.json');
            const data = await exportCode.json();
            const exportPageCode = await fetch('/pageDataExport.json');
            const pageData = await exportPageCode.json();
            setJsonData({ data: data, pages: pageData });
        } catch (error) {
            console.error("Erreur lors du chargement des données JSON:", error);
        }
    };

    return (
        <div className='container'>

            {userData && !userData.isUserAuthorized ? <div className='content' style={{textAlign: 'center', padding: '50px'}}>Access denied</div> :
                <div className='content'>
                    <button onClick={() => setNav('home')}>Home</button>
                    <button onClick={() => setNav('qrcode')}>Génération médaillons</button>

                    {nav === 'home' && <ListUser jsonData={jsonData && jsonData} />}
                    {nav === 'qrcode' && <Qrcodes />}
                </div>
            }

        </div>
    )
}
