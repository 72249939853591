import React, { useState } from 'react';
import './CreateBiography.scss';
import ReactModal from 'react-modal';
import { getFunctions, httpsCallable } from "firebase/functions";

ReactModal.setAppElement('#root');

const CreateBiography = ({ isOpen, onClose, onImport }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        birthDatePlace: '',
        deathDatePlace: '',
        lifeHighlights: '',
        occupations: '',
        hobbies: '',
        achievements: '',
        characterTraits: '',
        familyMembers: '',
        anecdotes: '',
        communityContributions: '',
        favoritePlaces: '',
        favoriteQuotes: '',
    });
    const [biography, setBiography] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(0);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const functions = getFunctions();
        const generateBiography = httpsCallable(functions, 'generateBiography');
        console.log('formData:', formData);
        try {
            const result = await generateBiography(formData);
            console.log('Biography generated:', result);
            setBiography(result.data.biography);
        } catch (error) {
            console.error('Error generating biography:', error);
        }
        setIsLoading(false);
    };

    const nextStep = () => {
        if ((step === 1 && formData.fullName === '') ||
            (step === 2 && formData.birthDatePlace === '') ||
            (step === 3 && formData.deathDatePlace === '')) {
            alert("Veuillez remplir tous les champs obligatoires avant de continuer.");
        } else {
            setStep(step + 1);
        }
    };

    const prevStep = () => setStep(step - 1);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && step < 14) {
            e.preventDefault();
            nextStep();
        }
    };

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Generate Biography"
            className="modal-bio"
            overlayClassName="overlay"
        >
            <div className="modal-header-bio">
                <h4>Générer une Biographie</h4>
                <button onClick={onClose} className="modal-close-button-bio">X</button>
            </div>
            <div className="modal-body-bio">
                <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                    {step === 0 && (
                        <>
                            <p style={{textAlign: 'center'}}>Répondez à une dizaines de questions simples sur votre proche pour rédiger sa biographie.</p>
                            <small className='mt-10 mb-20' style={{textAlign: 'center'}}>Ne vous inquiétez pas si vous répétez certaines informations dans vos réponses.</small>
                            <b>Sommaire</b>
                            <ul>
                                <li>Nom complet</li>
                                <li>Date et lieu de naissance</li>
                                <li>Date et lieu de décès</li>
                                <li>Moments marquants de la vie</li>
                                <li>Métiers ou occupations</li>
                                <li>Centres d'intérêt, passions, hobbies</li>
                                <li>Moments de réussite ou accomplissements</li>
                                <li>Traits de caractère ou qualités humaines</li>
                                <li>Membres de la famille proches</li>
                                <li>Anecdotes ou souvenirs particuliers</li>
                                <li>Contributions ou impact sur la communauté</li>
                                <li>Lieux préférés ou significatifs</li>
                                <li>Citations ou mots de sagesse</li>
                            </ul>
                            <button type="button" className="bio-button" onClick={nextStep}>Commencer</button>
                        </>
                    )}
                    {step === 1 && (
                        <div className="form-group">
                            <label>1/13 - Nom complet ou surnom *</label>
                            <input type="text" placeholder="Nom complet ou surnom" name="fullName" value={formData.fullName} onChange={handleChange} required />
                        </div>
                    )}
                    {step === 2 && (
                        <div className="form-group">
                            <label>2/13 - Date et lieu de naissance *</label>
                            <input type="text" placeholder="Date et lieu de naissance" name="birthDatePlace" value={formData.birthDatePlace} onChange={handleChange} required />
                        </div>
                    )}
                    {step === 3 && (
                        <div className="form-group">
                            <label>3/13 - Date et lieu de décès *</label>
                            <input type="text" placeholder="Date et lieu de décès" name="deathDatePlace" value={formData.deathDatePlace} onChange={handleChange} required />
                        </div>
                    )}
                    {step === 4 && (
                        <div className="form-group">
                            <label>4/13 - Moments marquants de la vie</label>
                            <textarea placeholder="Moments marquants de la vie" name="lifeHighlights" value={formData.lifeHighlights} onChange={handleChange} />
                        </div>
                    )}
                    {step === 5 && (
                        <div className="form-group">
                            <label>5/13 - Métiers ou occupations</label>
                            <textarea placeholder="Métiers ou occupations" name="occupations" value={formData.occupations} onChange={handleChange} />
                        </div>
                    )}
                    {step === 6 && (
                        <div className="form-group">
                            <label>6/13 - Centres d'intérêt, passions, hobbies</label>
                            <textarea placeholder="Centres d'intérêt, passions, hobbies" name="hobbies" value={formData.hobbies} onChange={handleChange} />
                        </div>
                    )}
                    {step === 7 && (
                        <div className="form-group">
                            <label>7/13 - Moments de réussite ou accomplissements</label>
                            <textarea placeholder="Moments de réussite ou accomplissements" name="achievements" value={formData.achievements} onChange={handleChange} />
                        </div>
                    )}
                    {step === 8 && (
                        <div className="form-group">
                            <label>8/13 - Traits de caractère ou qualités humaines</label>
                            <textarea placeholder="Traits de caractère ou qualités humaines" name="characterTraits" value={formData.characterTraits} onChange={handleChange} />
                        </div>
                    )}
                    {step === 9 && (
                        <div className="form-group">
                            <label>9/13 - Membres de la famille proches</label>
                            <textarea placeholder="Membres de la famille proches" name="familyMembers" value={formData.familyMembers} onChange={handleChange} />
                        </div>
                    )}
                    {step === 10 && (
                        <div className="form-group">
                            <label>10/13 - Anecdotes ou souvenirs particuliers</label>
                            <textarea placeholder="Anecdotes ou souvenirs particuliers" name="anecdotes" value={formData.anecdotes} onChange={handleChange} />
                        </div>
                    )}
                    {step === 11 && (
                        <div className="form-group">
                            <label>11/13 - Contributions ou impact sur la communauté</label>
                            <textarea placeholder="Contributions ou impact sur la communauté" name="communityContributions" value={formData.communityContributions} onChange={handleChange} />
                        </div>
                    )}
                    {step === 12 && (
                        <div className="form-group">
                            <label>12/13 - Lieux préférés ou significatifs</label>
                            <textarea placeholder="Lieux préférés ou significatifs" name="favoritePlaces" value={formData.favoritePlaces} onChange={handleChange} />
                        </div>
                    )}
                    {step === 13 && (
                        <div className="form-group">
                            <label>13/13 - Citations ou mots de sagesse</label>
                            <textarea placeholder="Citations ou mots de sagesse" name="favoriteQuotes" value={formData.favoriteQuotes} onChange={handleChange} />
                        </div>
                    )}
                    <div className="form-navigation">
                        {step > 0 && <button type="button" className="bio-button" onClick={prevStep}>Précédent</button>}
                        {step < 13 && step > 0 && <button type="button" className="bio-button" onClick={nextStep}>Suivant</button>}
                        {step === 13 && <button type="submit" className="bio-button generate" disabled={isLoading}>{isLoading ? 'Génération...' : 'Générer'}</button>}
                    </div>
                </form>
                {biography && (
                    <div className="biography-result">
                        <h4>Biographie générée</h4>
                        <p>{biography}</p>
                    </div>
                )}
            </div>
            <div className="modal-footer-bio">
                <button className="bio-button back" onClick={onClose}>Retour</button>
                {biography && <button className="bio-button" onClick={() => onImport(biography)}>Importer</button>}
            </div>
        </ReactModal>
    );
};

export default CreateBiography;
