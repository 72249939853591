import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { exportMedia } from '../../firebase.service';
import Button from '../button/Button';
import Spinner from '../spinner/Spinner';

const ExportMedia = ({ data }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleExport = async () => {
        try {
            setIsLoading(true);
            const zipFile = await exportMedia(data.images, data.videos);
            const blob = new Blob([Uint8Array.from(atob(zipFile), c => c.charCodeAt(0))], { type: 'application/zip' });
            saveAs(blob, 'media.zip');
            localStorage.setItem('mediaExported', 'true');
            setIsLoading(false);
        } catch (error) {
            console.error('Error generating zip file', error);
            setIsLoading(false);
        }
    };

    return (
        <div>
            {isLoading ? <Spinner type='button' /> : <Button onClick={handleExport} icon='download' text="Télécharger toutes mes photos et vidéos" theme="primary" />}
        </div>
    );
};

export default ExportMedia;