import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signOut, createUserWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAt6CjuUBd-PHvfI1Bfvbq01rY_NRhAfFE",
    authDomain: "dernierinstant-e48d2.firebaseapp.com",
    projectId: "dernierinstant-e48d2",
    storageBucket: "dernierinstant-e48d2.appspot.com",
    messagingSenderId: "480622819244",
    appId: "1:480622819244:web:7458bd231d8779df486c09",
    measurementId: "G-FFXK33PD5K"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export const imageDb = getStorage(app);

export { auth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signOut, createUserWithEmailAndPassword, onAuthStateChanged, db, functions, httpsCallable };
