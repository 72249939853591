import React, { useState } from 'react';
import Button from './button/Button';
import Input from './input/Input';
import { checkIfEmailExist, userRegisterByEmail } from '../firebase.service';
import Alert from './alert/Alert';
import ProgressBar from './progressBar/ProgressBar';
import Spinner from './spinner/Spinner';
import { useTranslation } from 'react-i18next';

function Step1({ nextStep, handleChange, navigateBetweenTabs, values, errors, resetError }) {
    const close = () => {
        resetError('email');
    };
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const clickToNextStep = async () => {
        setIsLoading(true);
        await nextStep();
        setIsLoading(false);
    }
    return (
        <>
            <div className='login-title-container'>
                <Button onClick={() => navigateBetweenTabs('register')} icon='back' small />
                <span>{t('registerForm.addressTitle')}</span>
            </div>
            <div className='login-content'>
                {errors.email && <Alert theme='error' text={errors.email} onClose={close} />}
                <Input type='email' placeholder={t('registerForm.addressMail')} label={t('registerForm.addressMail')} icon='email' value={values.email} onChange={(e) => handleChange('email', e.target.value)} required />

                <ProgressBar steps={3} current={1} />
                {isLoading ? <Spinner type='button' /> : <Button text={t('registerForm.next')} theme='primary' onClick={clickToNextStep} />}
            </div>
        </>
    );
}

function Step2({ nextStep, prevStep, handleChange, values, errors, resetError }) {
    const [showPassword, setShowPassword] = useState(false);
    const {t} = useTranslation();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const close = () => {
        resetError('password');
        resetError('confirm');
    };

    const [isLoading, setIsLoading] = useState(false);
    const clickToNextStep = async (password, confirm) => {
        setIsLoading(true);
        await nextStep(password, confirm);
        setIsLoading(false);
    }

    return (
        <>
            <div className='login-title-container'>
                <Button onClick={prevStep} icon='back' small />
                <span>{t('registerForm.passwordTitle')}</span>
            </div>
            <div className='login-content'>
                {(errors.confirm || errors.password) && <Alert theme='error' text={(errors.confirm || errors.password)} onClose={close} />}

                <Input type={showPassword ? 'text' : 'password'} icon='password' placeholder={t('registerForm.password')} label={t('registerForm.password')} value={values.password} onChange={(e) => handleChange('password', e.target.value)} required />
                <small className='mb-20' style={{textAlign: 'center'}}>{t('registerForm.passwordDesc')}</small>
                <Input type={showPassword ? 'text' : 'password'} icon='password' placeholder={t('registerForm.confirmPassword')} label={t('registerForm.confirmPassword')} value={values.confirm} onChange={(e) => handleChange('confirm', e.target.value)} required />

                <div className='mt-20 mb-20'>
                    <Button text={showPassword ? t('registerForm.hidePassword') : t('registerForm.showPassword')} small icon={showPassword ? 'eye' : 'hide'} onClick={togglePasswordVisibility} />
                </div>

                <ProgressBar steps={3} current={2} />
                {isLoading ? <Spinner type='button' /> : <Button text={t('registerForm.next')} theme='primary' onClick={() => clickToNextStep(values.password, values.confirm)} />}
            </div>
        </>
    );
}

function Step3({ submitForm, prevStep, handleChange, values, errors, resetError }) {
    const close = () => {
        resetError('fullName');
    };

    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const clickToNextStep = async () => {
        setIsLoading(true);
        await submitForm();
        setIsLoading(false);
    }
    return (
        <>
            <div className='login-title-container'>
                <Button onClick={prevStep} icon='back' small />
                <span>{t('registerForm.fullNameTitle')}</span>
            </div>
            <div className='login-content'>
                {errors.fullName && <Alert theme='error' text={errors.fullName} onClose={close} />}
                <Input type='text' placeholder={t('registerForm.fullName')} label={t('registerForm.fullName')} icon='user' value={values.fullName} onChange={(e) => handleChange('fullName', e.target.value)} required />

                <ProgressBar steps={3} current={3} />
                {isLoading ? <Spinner type='button' /> : <Button text={t('registerForm.end')} theme='primary' onClick={clickToNextStep} />}
            </div>
        </>
    );
}

export default function RegisterForm({ navigateBetweenTabs }) {
    const [step, setStep] = useState(1);
    const {t} = useTranslation();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirm: '',
        fullName: ''
    });
    const [errors, setErrors] = useState({});

    const validateStep = (step) => {
        let tempErrors = {};
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expression régulière pour valider l'email
    
        switch (step) {
            case 1:
                if (!formData.email) {
                    tempErrors.email = t('registerForm.errorEmailRequired');
                } else if (!emailRegex.test(formData.email)) {
                    tempErrors.email = t('registerForm.errorMailInvalid');
                }
                break;
            case 2:
                if (!formData.password) {
                    tempErrors.password = t('registerForm.errorPasswordRequired');
                } else if (!passwordRegex.test(formData.password)) {
                    tempErrors.password = t('registerForm.errorPasswordInvalid');
                }
                if (!formData.confirm) {
                    tempErrors.confirm = t('registerForm.errorConfirmPasswordRequired');
                } else if (formData.password !== formData.confirm) {
                    tempErrors.confirm = t('registerForm.errorConfirmPasswordInvalid')
                }
                break;
            case 3:
                if (!formData.fullName) {
                    tempErrors.fullName = t('registerForm.errorFullNameRequired');
                }
                break;
            default:
                break;
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };    

    const resetError = (field) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    };

    const nextStep = async () => {
        if (step === 1) {
            const emailExists = await checkIfEmailExist(formData.email);
            if (emailExists.length > 0) {
                setErrors({ ...errors, email: t('registerForm.errorEmailUsed') });
                return;
            }
        }

        if (validateStep(step)) {
            setStep(step + 1);
        }
    };

    const prevStep = () => setStep(step - 1);

    const handleChange = (input, value) => {
        setFormData({ ...formData, [input]: value });
    };

    const submitFormData = async () => {
        if (validateStep(step)) {
            // console.log('Le formulaire est complet :', formData);
            await userRegisterByEmail(formData.email, formData.password, formData.fullName);
            // console.log(response)
        }
    };

    switch (step) {
        case 1:
            return <Step1 nextStep={nextStep} handleChange={handleChange} navigateBetweenTabs={navigateBetweenTabs} values={formData} errors={errors} resetError={resetError} />;
        case 2:
            return <Step2 prevStep={prevStep} nextStep={nextStep} handleChange={handleChange} values={formData} errors={errors} resetError={resetError} />;
        case 3:
            return <Step3 submitForm={submitFormData} prevStep={prevStep} handleChange={handleChange} values={formData} errors={errors} resetError={resetError} />;
        default:
            return <Step1 nextStep={nextStep} handleChange={handleChange} navigateBetweenTabs={navigateBetweenTabs} values={formData} errors={errors} resetError={resetError} />;
    }
}
