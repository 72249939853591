import React from 'react';
import './Saved.scss';

import checked from '../../assets/icons/checked.png';

export default function Saved({ value, message='Saved' }) {
    return (
        <div className={`saved ${value ? 'active' : ''}`}>
            <img src={checked} alt='checked' /> {message}
        </div>
    )
}
