import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Input from '../input/Input';

import './UploadYoutube.scss';
import Button from '../button/Button';
import { addYoutubeVideo, deleteYoutubeVideo } from '../../firebase.service';
import Spinner from '../spinner/Spinner';
import { useTranslation } from 'react-i18next';

const UploadYoutube = ({ pageData, refresh }) => {
    const [videoURL, setVideoURL] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const handlePublish = async () => {
        setIsLoading(true);
        console.log(pageData)
        await addYoutubeVideo(videoURL, pageData.id);
        refresh();
        setVideoURL('');
        setIsLoading(false);
    };

    const handleDeleteMedia = async (url) => {
        const isConfirmed = window.confirm(t('editMedia.confirmDelete'));
        if (!isConfirmed) {
            return;
        }
        setIsLoading(true);
        // console.log(url)
        await deleteYoutubeVideo(url, pageData.id);
        refresh();
        setIsLoading(false);
    };

    return (
        <>
            {isLoading ? <Spinner /> :
                <div>
                    <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>{t('editMedia.addVideos')} YouTube <small>{pageData && pageData.videos_youtube ? pageData.videos_youtube.length : 0}/ꝏ</small></h3>
                    <form className='mb-10' onSubmit={(e) => e.preventDefault()}>
                        <Input type="text" placeholder="Entrez l'URL YouTube" value={videoURL} onChange={(e) => setVideoURL(e.target.value)} required />
                    </form>

                    {videoURL && ReactPlayer.canPlay(videoURL) && (
                        <div className='preview-ytb'>
                            <ReactPlayer url={videoURL} controls={true} className='youtube-player-preview' />
                            <Button text="Ajouter la vidéo" theme="primary" onClick={handlePublish} icon='check' />
                        </div>
                    )}
                </div>
            }
            {pageData && pageData.videos_youtube && [...pageData.videos_youtube].reverse().map((video, index) => (
                <div key={index} className='video-item'>
                    <div className='edit-media-delete-button' onClick={() => handleDeleteMedia(video)}></div>
                    <ReactPlayer url={video} controls={true} className='youtube-player' />
                </div>
            ))}
        </>
    );
};

export default UploadYoutube;


// https://www.youtube.com/watch?v=lr4eAiV8DWw