import React, { useRef, useState } from 'react';
import './Account.scss';
import Header from '../../../components/header/Header';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth.context';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import { updateImage, updateUserName } from '../../../firebase.service';
import Spinner from '../../../components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../../components/languageSelector/LanguageSelector';

import { auth } from '../../../firebase.config';

import google from '../../../assets/icons/google.png';
import mail from '../../../assets/icons/mail.png';
import UploadPicture from '../../../components/uploadPicture/UploadPicture';

export default function Account() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { userData, refreshData } = useAuth();

    const [name, setName] = useState(userData && userData.displayName);
    const [isModified, setIsModified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
        setIsModified(true);
    };

    const updateName = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await updateUserName(name);
        refreshData();
        setIsLoading(false);
    };

    const uploadPictureRef = useRef();
    const handleProfilePictureClick = () => {
        uploadPictureRef.current.triggerFileInput();
    };

    return (
        <div className='container'>
            <Header back={() => navigate('/dashboard/home')} title={t('accountPage.account')} subtitle={userData && userData.displayName} power />

            <div className='content profile'>
                <div className='profile-picture'>
                    {isUploading ? <Spinner /> :
                        <img src={userData && userData.photoURL ? userData.photoURL : 'https://flaviendev.b-cdn.net/blank_profile.jpg'} alt='profile' onClick={handleProfilePictureClick} />}
                    {/* <UploadPicture ref={uploadPictureRef} onUploadStart={() => setIsUploading(true)} onUploadComplete={() => setIsUploading(false)} /> */}
                    <UploadPicture
                        ref={uploadPictureRef}
                        onUploadStart={() => setIsUploading(true)}
                        destination="user"
                        onUploadSuccess={async (url, destination) => {
                            // console.log('URL de l\'image :', url);
                            // console.log('Destination :', destination);
                            await updateImage(destination, url);
                            refreshData();
                            setIsUploading(false);
                        }}
                    />
                </div>

                <div className='profile-label'>
                    {t('accountPage.language')}
                </div>
                <LanguageSelector />

                <form onSubmit={updateName}>
                    <Input type='text' placeholder={t('accountPage.fullName')} label={t('accountPage.fullName')} value={name} onChange={handleNameChange} required />
                    {isModified && (
                        isLoading ? <Spinner /> :
                            <Button text={t('accountPage.saveChanges')} theme='success' type='submit' />
                    )}
                </form>
                <div className='profile-label'>
                    {t('accountPage.loginInformation')}
                </div>
                <div className='profile-info'>
                    <img src={auth.currentUser.providerData[0].providerId === 'google.com' ? google : mail} alt='icon auth' /> <span>{userData && userData.email}</span>
                </div>
                {/* <form>
                    {auth.currentUser.providerData[0].providerId === 'password' && <Button text='Modifier le mot de passe' theme='primary' onClick={() => console.log('Modifier mot de passe')} />}
                </form> */}
            </div>
        </div>
    )
}
