import React, { useEffect, useState } from 'react';
import './Home.dashboard.scss';
import Button from '../../../components/button/Button';
import { convertPage, createQRCodeListConvert, linkQrCodeToPage, qrCodeCheck } from '../../../firebase.service';
import { useAuth } from '../../../contexts/auth.context';
import ProfileBanner from '../../../components/profileBanner/ProfileBanner';

import plus from '../../../assets/icons/plus.png';
import Alert from '../../../components/alert/Alert';
import Scanner from '../../../components/scanner/Scanner';
import { useQrCode } from '../../../contexts/scanner.context';
import ListPage from '../../../components/listPage/ListPage';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/spinner/Spinner';
import { useTranslation } from 'react-i18next';

export default function HomeDashboard() {

    const { userData, refreshData } = useAuth();
    const [jsonData, setJsonData] = useState(null);
    const [oldMedaillon, setOldMedaillon] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alertInfo, setAlertInfo] = useState({});

    const navigate = useNavigate();
    const { t } = useTranslation();

    const { qrCode, resetQrCodeSession, checkAndSetQrCode } = useQrCode();
    const qrcodeSession = useQrCode();

    useEffect(() => {
        if (qrCode) {
            setAlertInfo({ visible: true, theme: 'success', message: t('homeDashboardPage.medallionAvailable') });
        }
    }, [qrCode])

    useEffect(() => {
        loadJsonData();
    }, [])

    useEffect(() => {
        findEmailByEmail(userData.email)
    }, [jsonData]);

    const loadJsonData = async () => {
        try {
            const exportCode = await fetch('/export.json');
            const data = await exportCode.json();
            const exportPageCode = await fetch('/pageDataExport.json');
            const pageData = await exportPageCode.json();
            setJsonData({ data: data, pages: pageData });
        } catch (error) {
            console.error("Erreur lors du chargement des données JSON:", error);
        }
    };

    const findEmailByEmail = async (mail) => {
        if (!jsonData) {
            return;
        }
        setIsLoading(true);
        let emailFound = false;
        let medaillonsToConvert = [];

        for (let email in jsonData.data) {
            if (email === mail) {
                emailFound = true;

                for (const id of jsonData.data[email]) {
                    const response = await qrCodeCheck(id);
                    if (!response) {
                        medaillonsToConvert.push(id);
                    }
                }

                if (medaillonsToConvert.length > 0) {
                    setOldMedaillon(medaillonsToConvert);
                }
                setIsLoading(false)
                return;
            }
        }

        if (!emailFound) {
            setIsLoading(false)
            // console.log('Pas de résultat');
        }
    };

    const initConvertPage = async (id) => {
        setIsLoading(true);

        // Vérification de l'existence des données JSON et des pages
        if (!jsonData || !jsonData.pages) {
            console.error("Données JSON ou pages manquantes");
            setIsLoading(false);
            return;
        }

        // Recherche de l'élément correspondant
        const foundItem = jsonData.pages.find(item => item.id === id);

        if (foundItem) {
            try {
                await createQRCodeListConvert(id);
                const data = {
                    biographie: foundItem.description ? foundItem.description + '<br /><br />' + (foundItem.bio || null) : null,
                    birth: foundItem.birthDate || null,
                    created: foundItem.created || null,
                    death: foundItem.deathDate || null,
                    images: foundItem.photos || [],
                    id: foundItem.id,
                    links: foundItem.links || [],
                    name: foundItem.fullName || 'Nouveau utilisateur',
                    photoURL: foundItem.photoURL || null,
                    owner: userData.uid
                };

                // Tentative de conversion de la page avec les données
                const response = await convertPage(data);
                if (response && response._key && response._key.path && response._key.path.segments[1]) {
                    await linkQrCodeToPage(response._key.path.segments[1], id);
                    refreshData();
                } else {
                    throw new Error("La réponse de la conversion de la page est invalide.");
                }
            } catch (error) {
                console.error("Erreur lors de la conversion de la page :", error);
            }
        } else {
            try {
                await createQRCodeListConvert(id);
                const response = await checkAndSetQrCode(id);
                scanResult(response);
                findEmailByEmail(userData.email);
            } catch (error) {
                console.error("Erreur lors de la création de la liste de QR codes ou de la vérification du QR code :", error);
            }
        }

        setIsLoading(false);
    };




    const scanResult = (result) => {
        // console.log(result)
        if (result === 'close') {
            setAlertInfo({ visible: false, theme: '', message: '' });
            resetQrCodeSession();
            return;
        }
        if (result.response === 'invalid') {
            setAlertInfo({ visible: true, theme: 'error', message: t('homeDashboardPage.medallionInvalid') });
        } else if (result.response === 'used') {
            setAlertInfo({ visible: true, theme: 'error', message: t('homeDashboardPage.medallionUsed') });
        } else if (result.response === 'available') {
            setAlertInfo({ visible: true, theme: 'success', message: t('homeDashboardPage.medallionAvailable') });
        } else {
            setAlertInfo({ visible: true, theme: 'error', message: t('homeDashboardPage.verificationError') });
        }
    }

    return (
        <div className='container'>
            <ProfileBanner user={userData} />

            <div className='alert-container-home'>
                {alertInfo.visible && <Alert theme={alertInfo.theme} text={alertInfo.message} onClose={() => scanResult('close')} />}
            </div>

            <div className='home-buttons-container'>
                {alertInfo.theme === "success" ? (
                    <div className='half-button secondary' style={{ maxWidth: '100%', height: '150px' }} onClick={() => navigate('/dashboard/create')}>
                        <img src={plus} alt='create' />
                        <b>{t('homeDashboardPage.createNewPage')}</b>
                        <small>{t('homeDashboardPage.linkMedallion')}</small>
                    </div>
                ) : (
                    <>
                        <Scanner response={scanResult} />
                        <div className='half-button secondary' onClick={() => navigate('/dashboard/create')}>
                            <img src={plus} alt='create' />
                            <b>{t('homeDashboardPage.createPage')}</b>
                            <small>{t('homeDashboardPage.createNewPageSmall')}</small>
                        </div>
                    </>
                )}
            </div>

            {!alertInfo.visible && oldMedaillon &&
                <div className='alert-container-conversion'>
                    <b>Vous avez un médaillon d'ancienne génération en attente de mise à jour</b>

                    {isLoading ? <Spinner type='button' /> : oldMedaillon.map((item, index) => (
                        <div key={index}>
                            <Button theme='danger' text={`Mettre à jour le médaillon : ${item}`} onClick={() => initConvertPage(item)} />
                        </div>
                    ))}
                </div>
            }

            <div className='content'>
                <h1 className='mb-20'>{qrcodeSession && !qrcodeSession.qrCode ? t('homeDashboardPage.myPages') : t('homeDashboardPage.linkPage')}</h1>

                <ListPage refresh={() => setAlertInfo({ visible: false, theme: '', message: '' })} />
                {/* <Button text='Déconnexion' theme='secondary' onClick={logOutUser} /> */}
            </div>

        </div>
    )
}
