import React, { useEffect, useState } from 'react';
import './Create.scss';
import Header from '../../../components/header/Header';
import { useNavigate } from 'react-router-dom';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import { useQrCode } from '../../../contexts/scanner.context';
import Alert from '../../../components/alert/Alert';
import { createPage, linkQrCodeToPage } from '../../../firebase.service';
import Spinner from '../../../components/spinner/Spinner';
import { useTranslation } from 'react-i18next';

export default function Create() {
    const navigate = useNavigate();
    const [alertInfo, setAlertInfo] = useState({});

    const [name, setName] = useState('');
    const [status, setStatus] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    // alert info
    const { qrCode, resetQrCodeSession } = useQrCode();
    const qrcodeSession = useQrCode();
    useEffect(() => {
        if (qrCode) {
            setAlertInfo({ visible: true, theme: 'success', message: t('createPage.availableMedallion') });
        }
    }, [qrCode])
    const scanResult = (result) => {
        if (result === 'close') {
            setAlertInfo({ visible: false, theme: '', message: '' });
            resetQrCodeSession();
            return;
        }
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        if (qrcodeSession && qrcodeSession.qrCode) {
            createPageWithCode();
        } else {
            createPageWithoutCode();
        }
    };

    const createPageWithoutCode = async () => {
        setIsLoading(true);
        const newPageId = await createPage(name, status);
        navigate(`/dashboard/edit/${newPageId}`);
        setIsLoading(false);
    }

    const createPageWithCode = async () => {
        setIsLoading(true);
        const newPageId = await createPage(name, status);
        if (newPageId) {
            await linkQrCodeToPage(newPageId, qrcodeSession.qrCode);
            resetQrCodeSession();
            navigate(`/dashboard/edit/${newPageId}`);
        } else {
            console.error('Erreur lors de la création de la page');
        }
        setIsLoading(false);
    }

    return (
        <div className='container'>
            <Header back={() => navigate('/dashboard/home')} title={t('createPage.newPage')} subtitle={t('createPage.createNewPage')} />
            <div className='alert-container-home'>
                {alertInfo.visible && <Alert theme={alertInfo.theme} text={alertInfo.message} onClose={() => scanResult('close')} />}
            </div>
            <div className='content'>
                <form onSubmit={handleSubmitForm}>
                    <Input type='text' placeholder={t('createPage.namePerson')} label={t('createPage.namePerson')} value={name} onChange={(e) => setName(e.target.value)} required />

                    <div className='status-label'>{t('createPage.pageStatus')}</div>
                    <div className='radio-toolbar'>
                        <input type='radio' id='public' name='status' value='public' required onChange={(e) => setStatus(e.target.value)} />
                        <label htmlFor='public'>{t('createPage.public')}</label>

                        <input type='radio' id='hidden' name='status' value='hidden' required onChange={(e) => setStatus(e.target.value)} />
                        <label htmlFor='hidden'>{t('createPage.hidden')}</label>

                        <input type='radio' id='private' name='status' value='private' required onChange={(e) => setStatus(e.target.value)} />
                        <label htmlFor='private'>{t('createPage.private')}</label>
                    </div>
                    <div className='infos-status'>
                        {status === 'public' && <span><b>{t('createPage.public')} :</b> {t('createPage.publicDesc')}</span>}
                        {status === 'private' && <span><b>{t('createPage.private')} :</b> {t('createPage.privateDesc')}</span>}
                        {status === 'hidden' && <span><b>{t('createPage.hidden')} :</b> {t('createPage.hiddenDesc')}</span>}
                    </div>
                    {isLoading ? <Spinner /> :
                        (status === '' || name === '') ? <Button type='button' text={t('createPage.createPage')} /> : <Button type='submit' text={t('createPage.createPage')} theme='primary' />
                    }
                </form>
            </div>
        </div>
    )
}
