import React, { useEffect, useRef, useState } from 'react'
import { allQrCodes, createQRCodeList, updateQRCodesExportedStatus } from '../../firebase.service';
import { v4 as uuidv4 } from 'uuid';
import Input from '../input/Input';
import Button from '../button/Button';

import QrCodePNG from './QrCodePNG';
import QrCodePNG_ANIMAL from './QrCodePNG_ANIMAL';

export default function Qrcodes() {
    const [qrcodes, setQrcodes] = useState([]);
    const [progress, setProgress] = useState(0);
    const [isGenerationComplete, setIsGenerationComplete] = useState(false);

    const [progressExport, setProgressExport] = useState(0);

    const [amount, setAmount] = useState(10);

    const [showAnimalQRCode, setShowAnimalQRCode] = useState(false);

    const qrCodeRefs = useRef([]);

    useEffect(() => {
        getQrcodes();
    }, [])

    useEffect(() => {
        if (amount > 101) setAmount(100)
    }, [amount])

    const getQrcodes = async () => {
        const response = await allQrCodes();
        setQrcodes(response);
        console.log(response);
    }

    const generateUniqueUrls = async (count) => {
        if (count !== 0 && count <= 100) {
            setIsGenerationComplete(false);
            for (let i = 0; i < count; i++) {
                const uniqueId = uuidv4();
                if(showAnimalQRCode)
                    await createQRCodeList(uniqueId, 'animal');
                else
                    await createQRCodeList(uniqueId);
                console.log(uniqueId + ' created');
                setProgress((prevProgress) => prevProgress + 1);
            }
            setIsGenerationComplete(true);
            setProgress(0);
            getQrcodes();
        }
    };

    const exportQRCodes = async () => {
        const nonExportedQRCodes = qrcodes.filter(qrcode => !qrcode.exported);
        const updatePromises = nonExportedQRCodes.map(qrcode => updateQRCodesExportedStatus(qrcode.uid, true));
        await Promise.all(updatePromises);
        setProgressExport(0);
        for (let i = 0; i < nonExportedQRCodes.length; i++) {
            const exportFn = qrCodeRefs.current[nonExportedQRCodes[i].uid];
            if (typeof exportFn === 'function') {
                await exportFn();
                await new Promise(resolve => setTimeout(resolve, 100));
                setProgressExport(prevProgress => prevProgress + 1);
            }
        }
        getQrcodes();
    };



    // Calcul des statistiques
    const notExportedQRCodes = qrcodes.filter(qrcode => !qrcode.exported);

    return (

        <div>
            Total généré : {qrcodes.length} <br />
            Disponible : {qrcodes.filter(qrcode => qrcode.available).length} <br />
            Activé : {qrcodes.filter(qrcode => !qrcode.available).length} <br />
            Lancé en production : {qrcodes.filter(qrcode => qrcode.exported).length} <br />
            Disponible à l'exportation : {qrcodes.filter(qrcode => !qrcode.exported).length} <br />

            <div style={{ maxWidth: '300px'}}>
                {/* Bouton pour basculer entre les composants */}
                <Button
                    text={showAnimalQRCode ? 'QRCode animal actif' : 'QRCode classique actif'}
                    onClick={() => setShowAnimalQRCode(!showAnimalQRCode)}
                    theme='primary'
                />
            </div>
            <Input type='text' label='Quantité (max:100)' value={amount} onChange={(e) => setAmount(e.target.value)} />
            {/* <button onClick={() => generateUniqueUrls(amount)}>Générer {amount} médaillons</button> */}
            <Button text={`Générer ${amount} médaillons`} onClick={() => generateUniqueUrls(amount)} theme='primary' />
            <br />
            {progress > 0 && (
                <div>
                    <p>Progression : {progress}/{amount}</p>
                    <progress value={progress} max={amount}></progress>
                </div>
            )}
            {isGenerationComplete && <p>Création terminée.</p>}

            {progressExport > 0 && (
                <div>
                    <p>Exportation : {progressExport}/{notExportedQRCodes.length}</p>
                    {notExportedQRCodes.length !== 0 && <progress value={progressExport} max={notExportedQRCodes.length}></progress>}
                </div>
            )}
            <div className='mt-40'>
                {notExportedQRCodes.length !== 0 && (
                    <Button
                        text={`Exporter ${notExportedQRCodes.length} médaillons`}
                        onClick={() => exportQRCodes()}
                        theme='primary'
                    />
                )}

                {notExportedQRCodes.map((data) => (
                    showAnimalQRCode ? (
                        <QrCodePNG_ANIMAL
                            key={data.uid}
                            url={data.url}
                            onExport={(exportFn) => qrCodeRefs.current[data.uid] = exportFn}
                        />
                    ) : (
                        <QrCodePNG
                            key={data.uid}
                            url={data.url}
                            onExport={(exportFn) => qrCodeRefs.current[data.uid] = exportFn}
                        />
                    )
                ))}
            </div>
        </div>
    )
}
