import React from 'react';
import './LanguageSelector.scss';
import i18n from '../../i18n';

import fr from '../../assets/flags/fr.png';
import uk from '../../assets/flags/uk.png';
// import de from '../../assets/flags/de.png';
import es from '../../assets/flags/es.png';
// import it from '../../assets/flags/it.png';
// import pt from '../../assets/flags/pt.png';

export default function LanguageSelector() {

    function changeLanguage(language) {
        i18n.changeLanguage(language);
    }

    function getButtonClassName(language) {
        return i18n.language === language ? 'active' : '';
    }

    return (
        <div className='language-selector-container'>
            <button className={getButtonClassName('en')} onClick={() => changeLanguage('en')}>
                <img src={uk} alt='en' />
            </button>
            <button className={getButtonClassName('fr')} onClick={() => changeLanguage('fr')}>
                <img src={fr} alt='fr' />
            </button>
            {/* <button className={getButtonClassName('de')} onClick={() => changeLanguage('de')}>
                <img src={de} alt='de' />
            </button> */}
            <button className={getButtonClassName('es')} onClick={() => changeLanguage('es')}>
                <img src={es} alt='es' />
            </button>
            {/* <button className={getButtonClassName('it')} onClick={() => changeLanguage('it')}>
                <img src={it} alt='it' />
            </button>
            <button className={getButtonClassName('pt')} onClick={() => changeLanguage('pt')}>
                <img src={pt} alt='pt' />
            </button> */}

        </div>
    )
}
