import React, { useEffect, useRef, useState } from 'react';
import './EditMedia.scss';
import { deleteImage, deleteVideo, displayProductName, getAllStripeSubscriptions, updateAnimalMask, updateImage, updateVideo } from '../../../firebase.service';
import UploadPicture from '../../uploadPicture/UploadPicture';
import UploadVideo from '../../uploadVideo/UploadVideo';
import ReactPlayer from 'react-player';
import ModalImage from 'react-modal-image';
import Spinner from '../../spinner/Spinner';

import imageIcon from '../../../assets/icons/image.png';
import close from '../../../assets/icons/close.png';
import plusIcon from '../../../assets/icons/plus.png';
import alertIcon from '../../../assets/icons/alert.png';
// animal btn
import catMask from '../../../assets/masks/cat.svg';
import dogMask from '../../../assets/masks/dog.svg';
import heartMask from '../../../assets/masks/heart.svg';

import { useTranslation } from 'react-i18next';
import Album from '../../cloudinary/Album';
import VideoUploader from '../../cloudinary/VideoUploader';
import Button from '../../button/Button';
import ModalPlan from '../../stripe/ModalPlan';
import StripeActivity from '../../stripe/StripeActivity';
import ExportMedia from '../../export-media/ExportMedia';
import ModalUpdateInfo from '../../modal-update-info/ModalUpdateInfo';
import Alert from '../../alert/Alert';
import UploadYoutube from '../../uploadYoutube/UploadYoutube';

export default function EditMedia({ data, refresh, qrCodeData }) {
    const uploadCoverRef = useRef();
    const uploadMainRef = useRef();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const [isUploading, setIsUploading] = useState({ cover: false, main: false, image: false, video: false });
    const [media, setMedia] = useState({ images: [], videos: [] });

    useEffect(() => {
        if (data) {
            setMedia({ images: data.images || [], videos: data.videos || [] });
            // console.log(data);
        }
    }, [data]);

    const handleUploadStart = (type) => setIsUploading({ ...isUploading, [type]: true });
    const handleUploadEnd = (type) => setIsUploading({ ...isUploading, [type]: false });
    const hasStorageVideos = data.videos && data.videos.some(video => video.includes('firebasestorage'));
    const hasOldGallery = data.images && data.images.length > 0 || hasStorageVideos;

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleMediaChange = (type, url, operation) => {
        if (operation === 'add') {
            if (type === 'cover' || type === 'main') {
                setMedia({ ...media, [type]: url });
            } else {
                setMedia({ ...media, [type]: [...media[type], url] });
            }
        } else if (operation === 'remove') {
            if (type === 'cover' || type === 'main') {
                setMedia({ ...media, [type]: '' });
            } else {
                setMedia({ ...media, [type]: media[type].filter((item) => item !== url) });
            }
        }
    };

    const handleDeleteMedia = async (type, url) => {
        const isConfirmed = window.confirm(t('editMedia.confirmDelete'));
        if (!isConfirmed) {
            return;
        }
        handleUploadStart(type);
        if (type === 'images') {
            await deleteImage('page', url, data.id);
            handleMediaChange(type, url, 'remove');
        } else if (type === 'videos') {
            await deleteVideo(url, data.id);
            handleMediaChange(type, url, 'remove');
        } else {
            if (type === 'cover') {
                await deleteImage('cover', data.coverURL, data.id);
                setMedia({ ...media, coverURL: '' });
            } else if (type === 'main') {
                await deleteImage('main', data.photoURL, data.id);
                setMedia({ ...media, photoURL: '' });
            }
        }
        handleUploadEnd(type);
        refresh();
    };

    const deleteAllMedia = async () => {
        const isExported = localStorage.getItem('mediaExported');
        if (!isExported) {
            alert("Veuillez exporter vos médias avant de procéder à la mise à jour, car tous vos médias seront supprimés.");
            return;
        }

        const isConfirmed = window.confirm(t('editMedia.confirmDeleteAll'));
        if (!isConfirmed) {
            return;
        }

        setIsUpdating(true);

        handleUploadStart('images');
        handleUploadStart('videos');

        try {
            for (let image of media.images) {
                await deleteImage('page', image, data.id);
            }
            for (let video of media.videos) {
                await deleteVideo(video, data.id);
            }
            setMedia({ images: [], videos: [] });
        } catch (error) {
            console.error('Error deleting media:', error);
        }

        handleUploadEnd('images');
        handleUploadEnd('videos');
        setIsUpdating(false);
        refresh();
    };

    const [activeSection, setActiveSection] = useState(null);
    const toggleStripeMenu = (section) => {
        if (section === 'all') {
            setActiveSection(null);
        } else {
            setActiveSection(activeSection === section ? null : section);
        }
    };

    useEffect(() => {
        getStripeSubs(data.owner, data.id);
    }, [])

    const [allLicences, setAllLicences] = useState(null);
    const [activeLicence, setActiveLicence] = useState(null);

    const getStripeSubs = async (uid, pageId) => {
        const response = await getAllStripeSubscriptions(uid);
        setAllLicences(response);

        const subscriptionEvents = response.flatMap(subscription => subscription.events)
            .reduce((acc, event) => {
                if (event.page_id === pageId) {
                    const subscriptionId = event.data?.subscription_id;
                    if (subscriptionId) {
                        if (!acc[subscriptionId]) {
                            acc[subscriptionId] = [];
                        }
                        acc[subscriptionId].push(event);
                    }
                }
                return acc;
            }, {});

        const latestSubscriptionStatus = Object.keys(subscriptionEvents).reduce((acc, subscriptionId) => {
            const events = subscriptionEvents[subscriptionId];
            events.sort((a, b) => b.event_created - a.event_created);
            acc[subscriptionId] = events[0].data.status;
            return acc;
        }, {});

        const activeSubscriptions = Object.keys(latestSubscriptionStatus)
            .filter(subscriptionId => latestSubscriptionStatus[subscriptionId] === 'active')
            .map(subscriptionId => {
                const product = subscriptionEvents[subscriptionId].find(event => event.data.status === 'active').data.plan.product;
                return product;
            });

        setActiveLicence(activeSubscriptions);
    };

    useEffect(() => {
        if (activeLicence)
            setQuantity();
    }, [activeLicence])

    const [photoQuantity, setPhotoQuantity] = useState(50);
    const [videoQuantity, setVideoQuantity] = useState(3);
    const setQuantity = () => {
        let newPhotoQuantity = photoQuantity;
        // let newVideoQuantity = videoQuantity;

        if (activeLicence.includes('prod_QTzoyC91AxIvhG')) {
            newPhotoQuantity += 50;
        }
        if (activeLicence.includes('prod_QTzoXBV5frQ4Jx')) {
            newPhotoQuantity += 100;
        }
        if (activeLicence.includes('prod_QTzpFybNWLcKpQ')) {
            newPhotoQuantity += 200;
        }
        if (activeLicence.includes('prod_QTzpafcO6f1I9q')) {
            newPhotoQuantity += 500;
        }

        // if (activeLicence.includes('prod_QGvJllXxu5hVS7')) {
        //     newVideoQuantity += 5;
        // }
        // if (activeLicence.includes('prod_QGvKLbSqnC5Q7R')) {
        //     newVideoQuantity += 10;
        // }
        // if (activeLicence.includes('prod_QGzJhLC4kfDPrS')) {
        //     newVideoQuantity += 20;
        // }
        // if (activeLicence.includes('prod_QGzKzE4ixZWLI6')) {
        //     newVideoQuantity += 50;
        // }

        setPhotoQuantity(newPhotoQuantity);
        // setVideoQuantity(newVideoQuantity);
    }

    // DEROGATION FOR UNLOCKING ALL FEATURES

    const [isMaskLoading, setIsMaskLoading] = useState(false);
    // update mak animal
    const updateMask = async (mask) => {
        // console.log(data.id, mask);
        setIsMaskLoading(true);
        await updateAnimalMask(mask, data.id);
        await refresh();
        setIsMaskLoading(false);
    }

    return (
        <div className='edit-media'>
            {!hasOldGallery && (data.qrcodeID || data.derogation) && (
                <div className='mb-20'>
                    <div className='subscription-container'>
                        <p>
                            Pack actuel
                            <small>50 photos / 3 vidéos</small>
                            {activeLicence && activeLicence.map((product, index) => (
                                <span className='badge-sub' key={index}>{displayProductName(product)}</span>
                            ))}
                        </p>
                        {activeSection !== null ? <button className='history-close-btn' onClick={() => toggleStripeMenu('all')}><img src={close} /></button> : <Button theme='light' icon='history' onClick={() => toggleStripeMenu('subscriptions')} small />}
                    </div>
                    {activeSection === null && <Button theme='primary' text='Ajouter des options' onClick={() => toggleStripeMenu('upgrade')} icon='plus' />}

                    {activeSection === 'upgrade' && <ModalPlan pageData={data} activeLicences={activeLicence} />}
                    {activeSection === 'subscriptions' && <StripeActivity pageData={data} licences={allLicences} />}
                </div>
            )}

            {activeSection === null &&
                <>
                    <div className='edit-media-cover'>
                        {data.coverURL && (
                            <div className='edit-media-delete-button' onClick={() => handleDeleteMedia('cover', data.coverURL)}></div>
                        )}
                        {isUploading.cover ? <Spinner /> : (
                            data.coverURL ? <img src={data.coverURL} alt='cover' onClick={() => uploadCoverRef.current.triggerFileInput()} /> : (
                                <div className='edit-media-new' onClick={() => uploadCoverRef.current.triggerFileInput()}>
                                    <img src={imageIcon} alt='icon' />
                                    {t('editMedia.addCover')}
                                </div>
                            )
                        )}
                        {data.coverURL && <div className='edit-media-title-absolute'>{t('editMedia.cover')}</div>}
                        <UploadPicture
                            ref={uploadCoverRef}
                            onUploadStart={() => handleUploadStart('cover')}
                            destination="cover"
                            onUploadSuccess={async (url) => {
                                await updateImage('cover', url, data.id);
                                handleMediaChange('cover', url, 'add');
                                handleUploadEnd('cover');
                                refresh();
                            }}
                        />
                    </div>

                    <div className='edit-media-main mt-20'>
                        {data.photoURL && (
                            <div className='edit-media-delete-button' onClick={() => handleDeleteMedia('main', data.photoURL)}></div>
                        )}
                        {isUploading.main ? <Spinner /> : (
                            data.photoURL ? <img src={data.photoURL} alt='main' onClick={() => uploadMainRef.current.triggerFileInput()} /> : (
                                <div className='edit-media-new' onClick={() => uploadMainRef.current.triggerFileInput()}>
                                    <img src={imageIcon} alt='icon' />
                                    {t('editMedia.addMainPicture')}
                                </div>
                            )
                        )}
                        {data.photoURL && <div className='edit-media-title-absolute'>{t('editMedia.mainPicture')}</div>}
                        <UploadPicture
                            ref={uploadMainRef}
                            onUploadStart={() => handleUploadStart('main')}
                            destination="main"
                            onUploadSuccess={async (url) => {
                                await updateImage('main', url, data.id);
                                handleMediaChange('main', url, 'add');
                                handleUploadEnd('main');
                                refresh();
                            }}
                        />
                    </div>

                    {qrCodeData && qrCodeData.animal && (
                        <>
                            <h3 className='mt-20'>Contour photo principale</h3>
                            {isMaskLoading ? <Spinner type='button' /> :
                                <div className='edit-mask-animal'>
                                    <button type='button' onClick={() => updateMask(null)} className={data.mask === null ? 'active' : ''}><img src={close} alt="Close Mask" /></button>
                                    <button type='button' onClick={() => updateMask('catMask')} className={data.mask === 'catMask' ? 'active' : ''}><img src={catMask} alt="Cat Mask" /></button>
                                    <button type='button' onClick={() => updateMask('dogMask')} className={data.mask === 'dogMask' ? 'active' : ''}><img src={dogMask} alt="Dog Mask" /></button>
                                    <button type='button' onClick={() => updateMask('heartMask')} className={data.mask === 'heartMask' ? 'active' : ''}><img src={heartMask} alt="Heart Mask" /></button>
                                </div>
                            }
                        </>
                    )}

                    {hasOldGallery ? (
                        <>
                            <div className='alert-update-container'>
                                <div className='alert-update-info'>
                                    Une nouvelle version de la galerie photos et vidéos est disponible !
                                    <button onClick={openModal}>En savoir plus sur la mise à jour</button>
                                </div>
                                <div className='alert-update-danger'>
                                    Pour migrer vers cette nouvelle version, il est nécessaire de supprimer tous vos médias actuels. (Photos et vidéos) <br /><br />
                                    Pas de panique, vous pourrez les remettre une fois que vous aurez effectué la mise à jour.<br /><br />
                                    Vous avez la possibilité d'exporter toutes vos photos et vidéos avant de procéder à la mise à jour.
                                </div>

                                <ExportMedia data={data} />

                                <div className='alert-update-button'>
                                    <img className='alert-img' src={alertIcon} alt='alert' />

                                    <b>||| ATTENTION ||| <br /> Aucun retour en arrière possible !</b>

                                    {isUpdating ?
                                        <Spinner type='button' />
                                        :
                                        <Button theme='danger' icon='warning' text='Mettre à jour la gallerie' onClick={deleteAllMedia} />
                                    }
                                </div>
                            </div>
                            {data.images && (
                                <>
                                    <div className='edit-media-label mt-20'>Photos</div>
                                    <div className='edit-media-gallery'>
                                        {media.images.map((image, index) => (
                                            <div key={index} className='edit-media-gallery-image'>
                                                <div className='edit-media-delete-button' onClick={() => handleDeleteMedia('images', image)}></div>
                                                <ModalImage className='image' small={image} large={image} />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}

                            <div className='edit-media-label mt-20'>Vidéos</div>
                            <div className='edit-media-gallery video'>
                                {media.videos.map((video, index) => (
                                    video.includes('firebasestorage') && (
                                        <div key={index} className='edit-media-gallery-video'>
                                            <div className='edit-media-delete-button' onClick={() => handleDeleteMedia('videos', video)}></div>
                                            <ReactPlayer width='100%' height='100%' url={video} controls muted />
                                        </div>
                                    )
                                ))}
                            </div>
                        </>
                    ) : (

                        !data.qrcodeID && !data.derogation ? <div className='media-alert'>{t('editMedia.alertMedia1')} <b>{t('editMedia.alertMedia2')}</b></div> :
                            <>
                                <div className='mt-20'>
                                    <Album pageData={data} refresh={refresh} pictureMaxNumber={photoQuantity} />
                                </div>

                                <div className='mt-20'>
                                    <VideoUploader pageData={data} refresh={refresh} videoMaxNumber={videoQuantity} />
                                </div>

                                <div className='mt-20'>
                                    <UploadYoutube pageData={data} refresh={refresh} />
                                </div>
                            </>
                    )}
                </>
            }
            <ModalUpdateInfo isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
}
