import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../button/Button';

const Cancel = () => {
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        console.log('Stripe Cancel Response:', Object.fromEntries(queryParams.entries()));
    }, [location]);

    return (
        <div className="container mt-40" style={{textAlign: 'center'}}>
            <h1>Abonnement annulé</h1>
            <p className='mt-40 mb-40'>Votre mise à jour d'abonnement à était annulé. Si vous pensez qu'il s'agit d'une erreur, contactez le service client.</p>
            <Button text="Retour" theme="primary" onClick={() => navigate('dashboard')} />
        </div>
    );
};

export default Cancel;