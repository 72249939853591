import React, { useEffect, useState } from 'react';
import './Edit.scss';
import Header from '../../../components/header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { getPageById, getQrCodeData } from '../../../firebase.service';
import Spinner from '../../../components/spinner/Spinner';

import comment from '../../../assets/icons/comment.png';
import share from '../../../assets/icons/share.png';
import animal from '../../../assets/icons/animal.png';

import EditInfo from '../../../components/edit/edit-info/EditInfo';
import EditMedia from '../../../components/edit/edit-media/EditMedia';
import EditVisit from '../../../components/edit/edit-visit/EditVisit';
import EditComment from '../../../components/edit/edit-comment/EditComment';
import { useTranslation } from 'react-i18next';
import Licence from '../../../components/licence/Licence';
import Button from '../../../components/button/Button';

export default function Edit() {
    const navigate = useNavigate();
    const [pageData, setPageData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [qrCodeData, setQrCodeData] = useState(null);

    const pageId = useParams().id;
    const { t } = useTranslation();

    useEffect(() => {
        getPageData();
    }, []);

    const getPageData = async () => {
        const response = await getPageById(pageId);
        if (response) {
            setPageData(response);
            // console.log(response);
        } else {
            navigate('/dashboard/home');
        }
        if (response.qrcodeID) {
            getQrCode(response.qrcodeID);
        } else {
            setIsLoading(false);
        }
    };

    const getQrCode = async (qrcodeID) => {
        const response = await getQrCodeData(qrcodeID);
        if (response) {
            setQrCodeData(response);
        }
        setIsLoading(false);
    }

    const [tabs, setTabs] = useState('modifier');
    const navigateBetweenTabs = (tab) => {
        setTabs(tab);
    };

    const setupPageName = (name) => {
        if (name === 'visit') return t('editPage.visits');
        if (name === 'comment') return t('editPage.comments');
    };

    const calculateTotalViews = () => {
        if (!pageData.visits) return 0;
        return Object.values(pageData.visits).reduce((total, visit) => total + (visit.count || 0), 0);
    };

    const totalViews = calculateTotalViews();

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 10000) {
            return (num / 1000).toFixed(1) + 'k';
        } else {
            return num;
        }
    };

    return (
        isLoading ? <Spinner /> :
            <div className='container'>
                {(tabs === 'visit' || tabs === 'comment') ?
                    <Header back={() => navigateBetweenTabs('modifier')} title={pageData && pageData.name} setting={pageData.id} subtitle={setupPageName(tabs)} /> : (
                        <>
                            <Header back={() => navigate('/dashboard/home')} title={pageData && pageData.name} setting={pageData.id} />

                            {pageData && !pageData.qrcodeID && (
                                <div className='no-medallion-container'>
                                    <p>{t('setting.noMedallionLinked')}</p>
                                    <Button text={t('editPage.buyMedallion')} icon='check' theme='primary' onClick={() => window.open('https://dernierinstant.com', '_blank')} />
                                </div>
                            )}

                            {pageData && pageData.qrcodeID && (
                                <div className='vc-container'>
                                    <div className='item-vc visit' onClick={() => navigateBetweenTabs('visit')}>
                                        <img src={share} alt='hide' />
                                        <b>{formatNumber(totalViews)}</b>
                                        <small>{totalViews > 1 ? t('editPage.visits') : t('editPage.visit')}</small>
                                    </div>
                                    {qrCodeData && qrCodeData.animal ? (
                                        <div className='item-vc view' onClick={() => navigate(`/memory/${pageData.qrcodeID}`)}>
                                            <img src={animal} alt='hide' />
                                            {/* {t('setting.previewPage')} */}
                                            Apercu page
                                        </div>
                                    ) :
                                        <div className='item-vc' onClick={() => navigateBetweenTabs('comment')}>
                                            <img src={comment} alt='hide' />
                                            <b>{pageData && pageData.comment ? pageData.comment.length : 0}</b>
                                            <small>{t('editPage.comment')}{pageData && pageData.comment && pageData.comment.length > 1 && 's'}</small>
                                        </div>
                                    }
                                </div>
                            )}

                            <div className='toggle-tab-container'>
                                <div className={`toggle-background ${tabs === 'medias' && 'medias'}`}></div>
                                <div className={`toggle-button ${tabs === 'modifier' && 'active'}`} onClick={() => navigateBetweenTabs('modifier')}>
                                    {t('editPage.update')}
                                </div>
                                <div className={`toggle-button ${tabs === 'medias' && 'active'}`} onClick={() => navigateBetweenTabs('medias')}>
                                    {t('editPage.media')}
                                </div>
                            </div>

                            {/* {qrCodeData && qrCodeData.licence && <Licence qrCodeData={qrCodeData} refresh={getPageData} pageData={pageData} />} */}

                        </>
                    )}

                <div className='content'>
                    {tabs === 'modifier' && <EditInfo data={pageData} refresh={getPageData} qrCodeData={qrCodeData} />}
                    {tabs === 'medias' && <EditMedia data={pageData} refresh={getPageData} qrCodeData={qrCodeData} />}
                    {tabs === 'visit' && <EditVisit data={pageData} refresh={getPageData} />}
                    {tabs === 'comment' && <EditComment data={pageData} refresh={getPageData} />}
                </div>
            </div>
    )
}
