import React from 'react';
import './Alert.scss';

export default function Alert({theme, text, onClose, button=true}) {
    return (
        <div className={`alert ${theme}`}>
            {text}
            {button && <button type='button' onClick={onClose}></button>}
        </div>
    )
}
