// Params
// ########################################
// back : function
// title : string
// subtitle : string
// setting : boolean (optional) (default: false)
// power : boolean (optional) (default: false)
// ########################################
// Example
// <Header back={() => console.log('Back')} title='Title' subtitle='Subtitle' setting />


import React from 'react';
import './Header.scss';
import Button from '../button/Button';
import { useNavigate } from 'react-router-dom';
import { logOutUser } from '../../firebase.service';

export default function Header({ back, title, subtitle, setting, power }) {
    const navigate = useNavigate();
    return (
        <div className='header-container'>
            <Button small icon='back' theme='primary' onClick={back} />
            <div className={`header-content ${(!setting && !power) && 'right-ajust'}`}>
                <div className='header-title'>
                    {title}
                </div>
                {subtitle &&
                    <div className='header-subtitle'>
                        {subtitle}
                    </div>
                }
            </div>
            {setting && <Button small icon='setting' theme='primary' onClick={() => navigate(`/dashboard/setting/${setting}`)} />}
            {power && <Button small icon='power' theme='light' onClick={logOutUser} />}
        </div>
    )
}
