import React, { createContext, useContext, useEffect, useState } from 'react';
import { createUserDocument, getUserDocument } from '../firebase.service';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase.config';
import Spinner from '../components/spinner/Spinner';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const authorizedEmails = [
        'test@test.com',
        'contact.wpac@gmail.com'
    ];

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                let userData = await getUserDocument(user.uid);
                if (!userData) {
                    console.log('Le compte n\'existe pas, création en cours...');
                    await createUserDocument(user);
                    userData = await getUserDocument(user.uid);
                }
                const isUserAuthorized = authorizedEmails.includes(user.email);
                setUserData({...userData, isUserAuthorized});
            } else {
                setUserData(null);
            }
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const refreshData = async () => {
        setIsLoading(true);
        if (auth.currentUser) {
            const userData = await getUserDocument(auth.currentUser.uid);
            // Réappliquer la vérification d'autorisation lors de l'actualisation des données
            const isUserAuthorized = authorizedEmails.includes(auth.currentUser.email);
            setUserData({...userData, isUserAuthorized});
        }
        setIsLoading(false);
    };

    const contextValue = {
        userData,
        refreshData
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {isLoading ? <Spinner /> : children}
        </AuthContext.Provider>
    );
};
