import React, { useRef, useState } from 'react';
import './NewComment.scss';
import Input from '../../input/Input';
import { useAuth } from '../../../contexts/auth.context';
import { createComment } from '../../../firebase.service';
import Spinner from '../../spinner/Spinner';

import checked from '../../../assets/icons/checked.png';
import image from '../../../assets/icons/image.png';
import trash from '../../../assets/icons/trash.png';

import { v4 as uuidv4 } from 'uuid';
import UploadPicture from '../../uploadPicture/UploadPicture';
import { imageDb } from '../../../firebase.config';
import { ref as storageRef, deleteObject } from "firebase/storage";
import { useTranslation } from 'react-i18next';


export default function NewComment({ pageID, onSendComplete }) {

    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const { userData } = useAuth();
    // useEffect(() => {
    //     console.log(userData)
    // }, [userData])

    const [message, setMessage] = useState('');
    const addComment = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const data = {
            id: uuidv4(),
            createAt: new Date(),
            userID: userData.uid,
            moderated: false,
            message: message,
            image: imageComment ? imageComment : null,
            like: [],
            answer: []
        }
        await createComment(pageID, data);
        setMessage('');
        onSendComplete();
        setIsLoading(false);
    }

    const [imageComment, setImageComment] = useState(null);
    const uploadRef = useRef();
    const [isUploading, setIsUploading] = useState(false);
    const handleUpload = () => {
        uploadRef.current.triggerFileInput();
    };

    const deleteImage = async (imageUrl) => {
        setIsUploading(true);
        const imageRef = storageRef(imageDb, imageUrl);
        await deleteObject(imageRef);
        setImageComment(null)
        setIsUploading(false);
    }

    return (
        <div>
            {isLoading ? <Spinner type='button' /> :
                <>
                    <form className='new-comment-container' onSubmit={addComment}>
                        {isUploading ? <Spinner  type='button' /> : imageComment && (
                            <div className='new-comment-image'>
                                <button type='button' onClick={() => deleteImage(imageComment)}><img src={trash} alt='trash' /></button>
                                <img className='img' src={imageComment} alt='Comment' />
                            </div>
                        )}
                        <Input type='text' value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t('newComment.yourMessage')} label={t('newComment.postMemory')} required />
                        <div className='new-comment-buttons'>
                        {!imageComment && !isUploading && <button className='img' type='button' onClick={handleUpload}><img src={image} alt='new-comment' /></button>} 
                            <button type='submit'><img src={checked} alt='checked' /> {t('newComment.publish')}</button>
                        </div>
                    </form>
                    <UploadPicture
                        ref={uploadRef}
                        onUploadStart={() => setIsUploading(true)}
                        destination="comment"
                        onUploadSuccess={async (url, destination) => {
                            // await updateImage(destination, url, data.id);
                            console.log(url, destination);
                            setImageComment(url);
                            setIsUploading(false);
                        }}
                    />
                </>
            }
            <div className='separator'></div>
        </div>
    )
}
