import React from 'react';
import './ModalUpdateInfo.scss';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

const ModalUpdateInfo = ({ isOpen, onClose }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Informations sur la mise à jour"
            className="modal"
            overlayClassName="overlay"
        >
            <div className="modal-header">
                <h4>Informations sur la mise à jour</h4>
                <button onClick={onClose} className="modal-close-button">×</button>
            </div>
            <div className="modal-body">
                <p>
                    Nous sommes ravis de vous annoncer une mise à jour de notre galerie de photos et vidéos :<br /><br />

                    <strong>Création d'albums :</strong> Organisez vos photos et vidéos en albums.<br /><br />

                    <strong>Réorganisation des images :</strong> Modifiez l'ordre de vos images dans les albums.<br /><br />

                    <strong>Nouveau stockage :</strong> Profitez d'un stockage plus rapide et performant.<br /><br />

                    <strong>Extension de médias :</strong> Ajoutez plus d'images et de vidéos avec des abonnements.<br /><br />

                    Cette mise à jour nécessitera la suppression de tous vos médias actuels. Exportez vos médias avant de procéder à la mise à jour. Après la mise à jour, vous pourrez recharger vos médias et profiter des nouvelles fonctionnalités.
                </p>
            </div>


            <div className="modal-footer">
                <button onClick={onClose} className="modal-close-button">Retour</button>
            </div>
        </ReactModal>
    );
};

export default ModalUpdateInfo;
