import React, { useState, useEffect, useRef, useMemo } from 'react';
import './MemoryMedia.scss';
import { useTranslation } from 'react-i18next';
import albumPic from '../../../assets/icons/album.png';
import Button from '../../button/Button';
import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { format } from "@cloudinary/url-gen/actions/delivery";
import { auto } from "@cloudinary/url-gen/qualifiers/format";
import { getAllStripeSubscriptions } from '../../../firebase.service';
import Alert from '../../alert/Alert';
import ReactPlayer from 'react-player';

const cloudName = 'dn6yhfcdn';
const cld = new Cloudinary({ cloud: { cloudName } });

const VideoGallery = ({ videos, limit }) => {
    const memoizedVideos = useMemo(() => {
        return videos.slice(0, limit).map((publicId, index) => {
            const video = cld.video(publicId).delivery(format(auto()));
            return (
                <div key={index} className='video-item'>
                    <AdvancedVideo cldVid={video} controls />
                </div>
            );
        });
    }, [videos, limit]);

    return (
        <div className='memory-media-gallery-video'>
            {memoizedVideos}
            {videos.length > limit && <Alert theme='error' text='Des vidéos ne sont pas visibles' button={false} />}

        </div>
    );
};

export default function MemoryMedia({ data }) {
    const { t } = useTranslation();
    const [activeAlbum, setActiveAlbum] = useState(null);
    const [loadedAlbums, setLoadedAlbums] = useState({});
    const [videos, setVideos] = useState([]);
    const galleryRefs = useRef([]);
    const [activeLicence, setActiveLicence] = useState(null);
    const [photoQuantity, setPhotoQuantity] = useState(50);
    const [videoQuantity, setVideoQuantity] = useState(3);

    useEffect(() => {
        if (Array.isArray(data?.videos)) {
            setVideos(data.videos);
        }
    }, [data]);

    const openGallery = (album, index) => {
        if (!loadedAlbums[album.name]) {
            setLoadedAlbums(prevState => ({
                ...prevState,
                [album.name]: album
            }));
        }
        setActiveAlbum(index);
    };

    useEffect(() => {
        getStripeSubs(data.owner, data.id);
    }, []);

    const getStripeSubs = async (uid, pageId) => {
        const response = await getAllStripeSubscriptions(uid);
        // console.log("Stripe subs:", response);

        const subscriptionEvents = response.flatMap(subscription => subscription.events)
            .reduce((acc, event) => {
                if (event.page_id === pageId) {
                    const subscriptionId = event.data?.subscription_id;
                    if (subscriptionId) {
                        if (!acc[subscriptionId]) {
                            acc[subscriptionId] = [];
                        }
                        acc[subscriptionId].push(event);
                    }
                }
                return acc;
            }, {});

        const latestSubscriptionStatus = Object.keys(subscriptionEvents).reduce((acc, subscriptionId) => {
            const events = subscriptionEvents[subscriptionId];
            events.sort((a, b) => b.event_created - a.event_created);
            acc[subscriptionId] = events[0].data.status;
            return acc;
        }, {});

        const activeSubscriptions = Object.keys(latestSubscriptionStatus)
            .filter(subscriptionId => latestSubscriptionStatus[subscriptionId] === 'active')
            .map(subscriptionId => {
                const product = subscriptionEvents[subscriptionId].find(event => event.data.status === 'active').data.plan.product;
                return product;
            });

        // console.log('Active products:', activeSubscriptions);
        setActiveLicence(activeSubscriptions);
    };

    useEffect(() => {
        if (activeLicence)
            setQuantity();
    }, [activeLicence]);

    const setQuantity = () => {
        let newPhotoQuantity = photoQuantity;
        let newVideoQuantity = videoQuantity;

        if (activeLicence.includes('prod_QTzoyC91AxIvhG')) {
            newPhotoQuantity += 50;
        }
        if (activeLicence.includes('prod_QTzoXBV5frQ4Jx')) {
            newPhotoQuantity += 100;
        }
        if (activeLicence.includes('prod_QTzpFybNWLcKpQ')) {
            newPhotoQuantity += 200;
        }
        if (activeLicence.includes('prod_QTzpafcO6f1I9q')) {
            newPhotoQuantity += 500;
        }

        if (activeLicence.includes('prod_QGvJllXxu5hVS7')) {
            newVideoQuantity += 5;
        }
        if (activeLicence.includes('prod_QGvKLbSqnC5Q7R')) {
            newVideoQuantity += 10;
        }
        if (activeLicence.includes('prod_QGzJhLC4kfDPrS')) {
            newVideoQuantity += 20;
        }
        if (activeLicence.includes('prod_QGzKzE4ixZWLI6')) {
            newVideoQuantity += 50;
        }

        setPhotoQuantity(newPhotoQuantity);
        setVideoQuantity(newVideoQuantity);

        // console.log("Photo quantity:", newPhotoQuantity);
        // console.log("Video quantity:", newVideoQuantity);
    };

    useEffect(() => {
        if (activeAlbum !== null && galleryRefs.current[activeAlbum]) {
            const album = data.albums[activeAlbum];
            const totalPhotosDisplayed = data.albums.reduce((acc, album, index) => {
                if (index < activeAlbum) {
                    return acc + album.publicIds.length;
                }
                return acc;
            }, 0);
            const remainingPhotos = Math.max(0, photoQuantity - totalPhotosDisplayed);
            const photosToShow = album.publicIds.slice(0, remainingPhotos);
            const mediaAssets = photosToShow.map(publicId => ({ publicId, mediaType: 'image' }));
            const galleryInstance = window.cloudinary.galleryWidget({
                container: galleryRefs.current[activeAlbum],
                cloudName,
                mediaAssets
            });
            galleryInstance.render();
        }
    }, [activeAlbum, data.albums, photoQuantity]);

    return (

        data && (data.images && data.images.length === 0 || !data.images) ? (
            <>
                <div className='label-global mb-20'>{t('memoryMedia.picture')}</div>
                <div className='memory-media-albums'>
                    {data.albums && (() => {
                        let totalPhotosDisplayed = 0;
                        return data.albums.map((album, index) => {
                            if (totalPhotosDisplayed >= photoQuantity) {
                                return (
                                    <div key={index} className='album-container'>
                                        <button
                                            className='album-cover disabled'
                                            onClick={() => openGallery(album, index)}
                                            style={album.publicIds[0] ? { backgroundImage: `url('https://res.cloudinary.com/${cloudName}/image/upload/${album.publicIds[0]}')` } : {}}
                                        >
                                            <img src={albumPic} alt="Album cover" />
                                            <h1>{album.name}</h1>
                                            <div className='disabled-overlay'>Limite atteinte</div>
                                        </button>
                                    </div>
                                );
                            }

                            const remainingPhotos = Math.max(0, photoQuantity - totalPhotosDisplayed);
                            const photosToShow = album.publicIds.slice(0, remainingPhotos);
                            totalPhotosDisplayed += photosToShow.length;

                            return (
                                <div key={index} className='album-container'>
                                    {activeAlbum !== index && (
                                        <button
                                            className='album-cover'
                                            onClick={() => openGallery(album, index)}
                                            style={album.publicIds[0] ? { backgroundImage: `url('https://res.cloudinary.com/${cloudName}/image/upload/${album.publicIds[0]}')` } : {}}
                                        >
                                            <img src={albumPic} alt="Album cover" />
                                            <h1>{album.name}</h1>
                                        </button>
                                    )}
                                    {activeAlbum === index && (
                                        <>
                                            <b className='mb-20' style={{ display: 'block' }}>{data.albums[activeAlbum].name}</b>
                                            <div className='memory-media-gallery-container'>
                                                <div style={{ float: 'right', marginBottom: '20px' }}>
                                                    <Button theme='light' icon='close' small onClick={() => setActiveAlbum(null)} />
                                                </div>
                                                <div ref={el => galleryRefs.current[index] = el} className='memory-media-gallery'></div>
                                                {photosToShow.length < album.publicIds.length && <p className='mt-20 mb-20' style={{ color: 'red', textAlign: 'center' }}><b>Certaines images ne sont pas visibles</b></p>}
                                            </div>
                                        </>
                                    )}
                                </div>
                            );
                        });
                    })()}
                </div>

                <div className='label-global mt-40 mb-20'>{t('memoryMedia.video')}</div>
                <VideoGallery videos={videos} limit={videoQuantity} />

                {data && data.videos_youtube && [...data.videos_youtube].reverse().map((video, index) => (
                    <div key={index} className='video-item'>
                        <ReactPlayer url={video} controls={true} className='youtube-player' />
                    </div>
                ))}
            </>
        ) : (
            // old version
            <>
                <div className='label-global mb-20'>{t('memoryMedia.picture')}</div>
                <div className='memory-media-images-old'>
                    {data.images && data.images.map((image, index) => (
                        <div key={index} className='image-item'>
                            <img src={image} alt='Memory' />
                        </div>
                    ))}
                </div>

                <div className='label-global mt-40 mb-20'>{t('memoryMedia.video')}</div>
                <div className='memory-media-videos-old'>
                    {videos.map((video, index) => (
                        <div key={index} className='video-item'>
                            <ReactPlayer className='video' url={video} controls muted />
                        </div>
                    ))}
                </div>
            </>
        )
    );
}
