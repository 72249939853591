import React, { useEffect, useState } from 'react';
import './MemoryPassword.scss';
import Input from '../../input/Input';
import Button from '../../button/Button';
import Spinner from '../../spinner/Spinner';
import Alert from '../../alert/Alert';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth.context';
import { logOutUser } from '../../../firebase.service';
import { useTranslation } from 'react-i18next';

export default function MemoryPassword({ data, password, onValid }) {

    const [pass, setPass] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isAlert, setIsAlert] = useState(false);

    const {t} = useTranslation();

    const { userData } = useAuth();

    const navigate = useNavigate();
    const connection = () => {
        // console.log('Se connecter');
        // console.log(data.qrcodeID);
        if (userData) {
            logOutUser();
        }

        localStorage.setItem('redirect', data.qrcodeID);
        localStorage.getItem('redirect');
        // console.log(savedData);
        navigate('/login');
    }

    useEffect(() => {
        if (userData) {
            checkIfUserIsOnWhitelist();
        }
    }, [])

    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const checkIfUserIsOnWhitelist = () => {
        // console.log(data.owner);
        if (data.whitelist) {
            if (data.whitelist.includes(userData.email) || data.owner === userData.uid) {
                setIsWhitelisted(true);
            }
        }
    }

    const unlockForWhitelist = () => {
        onValid();
    }

    const handlePassword = (e) => {
        setIsLoading(true);
        setIsAlert(false);
        e.preventDefault();
        // console.log(password);

        if (pass === password.toString()) {
            setTimeout(() => {
                setIsLoading(false);
                onValid();
            }, 1000);
        } else {
            setIsAlert(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    }

    return (
        <div className='container'>
            <div className='content' style={{ textAlign: 'center' }}>
                <h1>{t('memoryPassword.thisPagePrivate')}</h1>
                {!isWhitelisted && (isLoading ? <Spinner /> :
                    <form onSubmit={handlePassword} className='mt-40'>
                        {isAlert && <Alert theme='error' text={t('memoryPassword.uncorrectPin')} onClose={() => setIsAlert(false)} />}
                        <Input type='number' placeholder={t('memoryPassword.enterPin')} value={pass} onChange={(e) => setPass(e.target.value)} required />
                        <Button type='submit' text={t('memoryPassword.unlockAccess')} theme='primary' icon='check' />
                    </form>
                )}
                {!userData ?
                    <div style={{ marginTop: '200px' }}>
                        <small>{t('memoryPassword.youAreWhitelisted')}</small>
                        <Button text={t('memoryPassword.login')} theme='light' onClick={connection} />
                    </div>
                    :
                    <div style={{ marginTop: '150px' }}>
                        {isWhitelisted ? (
                            <div>
                                <div className='mb-40'>{t('memoryPassword.mailIsOnWhitelist')} : <b>{userData.email}</b></div>
                                <Button text={t('memoryPassword.unlockAccess')} theme='primary' icon='check' onClick={unlockForWhitelist} />
                            </div>
                        ) : (
                            <div>
                                <div className='mb-40'>{t('memoryPassword.mailIsNotOnWhitelist')} : <b>{userData.email}</b></div>
                                <Button text={t('memoryPassword.loginWithAnotherAccount')} theme='light' onClick={connection} />
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}
