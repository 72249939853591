import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode.react';
import backgroundImageSrc from '../../assets/images/Background_export.png'; // Assurez-vous que ce chemin est correct

export default function QrCodePNG({url, onExport}) {
    const qrRef = useRef(null);

    useEffect(() => {
        // Fournissez la fonction d'exportation au parent via `onExport`
        onExport && onExport(onImageDownload);
        }, [onExport]);


    const onImageDownload = () => {
        const svgElement = qrRef.current.querySelector('svg');
        if (!svgElement) return;

        const svgData = new XMLSerializer().serializeToString(svgElement);
        const svgBase64 = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));

        const backgroundImage = new Image();
        backgroundImage.src = backgroundImageSrc;
        backgroundImage.onload = () => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const canvasSize = backgroundImage.width;
                canvas.width = canvasSize;
                canvas.height = canvasSize;

                const context = canvas.getContext('2d');
                context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

                const scale = 0.5;

                const marginTop = 0.04 * canvas.height;
                context.translate(canvas.width / 2, (canvas.height / 2) + marginTop);
                context.rotate((135 * Math.PI) / 180);
                context.scale(scale, scale);
                context.drawImage(image, -image.width / 2, -image.height / 2);
                context.setTransform(1, 0, 0, 1, 0, 0);
                const pngUrl = canvas.toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.download = `qrcode_${url}.png`;
                downloadLink.href = pngUrl;
                downloadLink.click();
            };
            image.src = svgBase64;
        };
    };

    return (
        <div ref={qrRef} className='qrcode-container' onClick={onImageDownload}>
            <QRCode
                className='qrcode'
                value={url}
                title={url}
                size={3450}
                renderAs='svg'
            />
        </div>
    );
}
