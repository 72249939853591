import React, { useEffect, useState } from 'react';
import './Comment.scss';

import { formatDistanceToNow } from 'date-fns';
import { fr, enUS , es } from 'date-fns/locale';

import trash from '../../assets/icons/trash.png';
import moderate from '../../assets/icons/moderate.png';
import moderate_true from '../../assets/icons/moderate_true.png';
import checked from '../../assets/icons/checked.png';

import { getUserById, moderateComment, removeAnswerFromComment, removeComment } from '../../firebase.service';
import Spinner from '../spinner/Spinner';

import { ref as storageRef, deleteObject } from "firebase/storage";
import { imageDb } from '../../firebase.config';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Answer({ dataAnswer, getUserInfo, formatTimestamp, deleteBtn }) {
    const [isLoading, setIsLoading] = useState(false);
    const [userAnswerInfo, setUserAnswerInfo] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        getUserInfo(dataAnswer.userID).then((response) => {
            setUserAnswerInfo(response);
            setIsLoading(false);
        });
    }, [dataAnswer.userID]);

    return (
        isLoading ? <Spinner type='button' /> :
            <div className='comment-answer'>
                <div className='comment-header'>
                    <img src={userAnswerInfo?.photoURL || 'https://flaviendev.b-cdn.net/blank_profile.jpg'} alt='avatar' />
                    <div className='comment-header-title'>
                        <div className='comment-header-title-name'>
                            <span>{userAnswerInfo?.displayName || 'lorem ipsum dolor'}</span> <button onClick={() => deleteBtn(dataAnswer.id)}><img src={trash} alt='Delete' /></button>
                        </div>
                        <div className='comment-header-title-date'>
                            <span>{dataAnswer && formatTimestamp(dataAnswer.createAt)}</span>
                        </div>
                    </div>
                </div>
                <div className='comment-text'>
                    {dataAnswer.message}
                </div>
            </div>
    );
}

export default function Comment({ post, data, refresh }) {

    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        setIsLoading(true);
        getUserInfo(data.userID).then((response) => {
            setUserInfo(response);
            setIsLoading(false);
        })
        // console.log('data', data)
    }, [data.userID])

    const deleteComment = async (data) => {
        const confirm = window.confirm(t('comment.deleteComment'));
        if (confirm) {
            await removeComment(post.id, data);
            if(data.image) {
                const imageRef = storageRef(imageDb, data.image);
                await deleteObject(imageRef);
            }
            refresh();
        }
    }

    const deleteAnswer = async (id) => {
        const confirm = window.confirm(t('comment.deleteAnswer'));
        if (confirm) {
            await removeAnswerFromComment(post.id, data.id, id);
            refresh();
        }
    }

    const [userInfo, setUserInfo] = useState(null);
    const getUserInfo = async (userID) => {
        const response = await getUserById(userID);
        return response;
    }

    const likeComment = async () => {
        // const response = await toggleLikeOnComment(post.id, data.id, userData.uid);
        // console.log(data.id, userData.uid);
        // console.log(response);
    }

    const approuveModeration = async () => {
        await moderateComment(post.id, data.id);
        refresh();
    }

    function formatTimestampToRelativeDate(timestamp) {
        let date;
        if (timestamp && typeof timestamp.toDate === 'function') {
            date = timestamp.toDate();
        } else if (timestamp) {
            date = new Date(timestamp);
        } else {
            return '';
        }
        const locales = { fr, en: enUS, es };
        const locale = locales[i18next.language.slice(0, 2)];
        return formatDistanceToNow(date, { addSuffix: true, locale });
    }

    return (
        isLoading ? <Spinner type='button' /> :
            <div className='comment'>

                <div className='comment-header'>
                    <img src={userInfo && userInfo.photoURL ? userInfo.photoURL : 'https://flaviendev.b-cdn.net/blank_profile.jpg'} alt='avatar' />
                    <div className='comment-header-title'>
                        <div className='comment-header-title-name'>
                            <span>{userInfo && userInfo.displayName}</span> <button onClick={() => deleteComment(data)}><img src={trash} alt='Delete' /></button>
                        </div>
                        <div className='comment-header-title-date'>
                            <span>{data && formatTimestampToRelativeDate(data.createAt)}</span> {data && post.isModerate && !data.moderated ? <img src={moderate} alt='Moderate' /> : <img src={moderate_true} alt='Moderate' />}
                        </div>
                    </div>
                </div>
                {data.image &&
                    <div className='comment-image'>
                        <img src={data.image} alt='comment' />
                    </div>
                }
                {data && post.isModerate && !data.moderated &&
                    <div className='comment-moderate'>
                        {t('comment.visibleComment')}
                        <button onClick={approuveModeration} style={{ cursor: 'pointer' }}><img src={checked} alt='checked' /> {t('comment.approve')}</button>
                    </div>
                }
                <div className='comment-text'>
                    {data && data.message}
                </div>
                <div className='comment-footer'>
                    <button className='like' onClick={() => likeComment()}>{data && data.like.length} like{data && data.like.length > 1 && 's'}</button> - <button>{data && data.answer.length} {t('comment.comment')}{data && data.answer.length > 1 && 's'}</button>
                </div>

                {data && data.answer.map((answer, index) => {
                    return <Answer key={index} dataAnswer={answer} getUserInfo={getUserInfo} formatTimestamp={formatTimestampToRelativeDate} deleteBtn={deleteAnswer} />
                })}
            </div>
    )
}
